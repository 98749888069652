import React, { useEffect } from "react";
import { Box, Card } from "@mui/material";
import { checkDependantQuestionTitle, displayIcon } from "../../helper/service";

const FirstScreen = ({ questionGroup, formData, questionNodes }: any) => {
  const [windowWidth, setWindowWidth] = React.useState(0);

  useEffect(() => {
    let width = window.innerWidth;
    setWindowWidth(width);
  }, []);

  return (
    <div
      id={questionGroup?.id}
      className="h-full mainInnerContent"
      style={{
        width: "100%",
        paddingTop: windowWidth > 767 ? "70px" : "270px",
        paddingBottom: windowWidth > 767 ? "20px" : "470px",
      }}
    >
      <Box className="successful-block">
        <Card
          variant="outlined"
          className="successful-widget"
          style={{ top: windowWidth < 380 ? "80%" : "50%" }}
        >
          <img
            src={displayIcon(questionGroup?.data?.boxIcon)}
            alt={questionGroup?.data?.boxIcon}
            style={{
              marginBottom: "12px",
              ...(questionGroup?.data?.boxIcon === "Kite" && { width: "50%" }),
            }}
          />
          <span
            style={{ fontWeight: 700, fontSize: "22px", marginTop: "12px" }}
          >
            {checkDependantQuestionTitle(questionGroup, formData)}
          </span>
          <p>{checkDependantQuestionTitle(questionNodes[0], formData)}</p>
        </Card>
      </Box>
    </div>
  );
};

export default FirstScreen;
