export default function YesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
    >
      <path
        d="M4 8.27788C4.18412 8.57551 4.41338 8.82564 4.60733 9.11477C4.88805 9.53326 5.16941 9.93715 5.46555 10.3422C5.65715 10.6043 5.86416 10.8509 6.02708 11.1362C6.16221 11.3728 6.36573 11.6459 6.45719 11.9087C6.53132 12.1217 6.62301 12.3223 6.73994 12.5138C6.91417 12.7992 7.39468 11.529 7.44882 11.4173C7.60267 11.0999 7.75209 10.7482 7.94265 10.4538C8.83379 9.07718 10.0668 7.89495 11.1884 6.74572C12.0765 5.83569 13.0514 5.05277 14 4.22217"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
