import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Survey from "../../assets/images/FormIcons/Survey.svg";

const SignInSignUpButton = ({ source = "", onStart }: any) => {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleSignIn = () => {
    navigate("/login?code=signup");
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="anonymousText">
      {source === "home" ? (
        <>
          <img src={Survey} alt="Survey" style={{ height: "60px" }} />
          <h1 style={{ fontWeight: "600", marginBottom: "10px" }}>
            <Button variant="text" onClick={onStart}>
              Get started
            </Button>{" "}
            to explore our free features, we're delighted to have you onboard.
          </h1>
          <p style={{ fontSize: "12px" }}>
            To fully assist you on your path to completing your green card
            journey, including understanding your progress, seeing PDF previews,
            and much more, we invite you to sign in or create an account. It’s
            quick, easy, and brings you one step closer to achieving your goals.
          </p>
        </>
      ) : source === "header" ? (
        <>
          <h1
            style={{
              fontWeight: "600",
              fontSize: `${windowWidth < 767 ? "12px" : "inherit"}`,
            }}
          >
            To save your data and use all features, please create free account or sign in
          </h1>
        </>
      ) : (
        <>
          <img src={Survey} alt="Survey" style={{ height: "60px" }} />
          <h1 style={{ fontWeight: "600", marginBottom: "10px" }}>
            Unlock Full Access to Streamline Your Green Card Journey
          </h1>
          <p style={{ fontSize: "12px" }}>
            You're currently exploring our free features, and we're delighted to
            have you onboard. To fully assist you on your path to completing
            your green card journey, including understanding your progress,
            seeing PDF previews, and much more, we invite you to sign in or
            create an account. It’s quick, easy, and brings you one step closer
            to achieving your goals.
          </p>
        </>
      )}
      <Box sx={{ marginTop: "10px", display: "flex" }}>
        <Button
          variant="contained"
          sx={{
            borderRadius: "20px",
            mr: 1,
            width: "200px",
            background: "#2563eb",
            boxShadow: "none",
            textTransform: "capitalize",
          }}
          onClick={handleSignIn}
        >
          Sign In / Sign Up
        </Button>
        {/* <Button
        variant="contained"
        sx={{
          borderRadius: "20px",
          mr: 1,
          textTransform: "capitalize",
          background: "#2563eb",
          width: "200px",
          boxShadow: "none",
        }}
      >
        Sign Up
      </Button> */}
      </Box>
    </div>
  );
};

export default SignInSignUpButton;
