export default function EyeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M0.666748 8.0013C0.666748 8.0013 3.33341 2.66797 8.00008 2.66797C12.6667 2.66797 15.3334 8.0013 15.3334 8.0013C15.3334 8.0013 12.6667 13.3346 8.00008 13.3346C3.33341 13.3346 0.666748 8.0013 0.666748 8.0013Z"
        stroke="#16A34A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        stroke="#16A34A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
