import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ReactJson from "react-json-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "reactflow/dist/style.css";
import "./index.css";

const FormPreview = (props: any) => {
  const { nodes } = props;
  const [selectedSection, setSelectedSection] = useState<any>({});
  const [selectedSections, setSelectedSections] = useState<string[]>([]);
  const [isExpandedAll, setIsExpandedAll] = useState<string>("collapse_all");

  useEffect(() => {
    if (isExpandedAll === "expand_all") {
      setSelectedSections(nodes?.map((node: any) => node.id));
    } else {
      setSelectedSections([]);
    }
  }, [isExpandedAll, nodes]);

  const renderBgColor = (nodesData: any) => {
    switch (nodesData?.data?.nodeType?.toLowerCase()) {
      case "section":
      case "text":
        return !!nodesData?.data?.nodeTitle ? "#fccaca" : null;
      case "sectionsummarygroup":
        return "#b7f1be";
      case "question":
        return "#feeca0";
      case "subquestiongroup":
        return "#a9d7fd";
      case "questiongroup":
        return "#ccccff";
      default:
        return null;
    }
  };

  const handleChange = (panel: any) => {
    let tempSections: string[] = [...selectedSections];

    if (tempSections.includes(panel.id)) {
      tempSections = tempSections?.filter((x) => x !== panel.id);
    } else {
      tempSections.push(panel?.id);
    }
    setSelectedSection(panel);
    setSelectedSections(tempSections);
  };

  const renderNodeBox = (nodesData: any) => (
    <Accordion
      key={nodesData.id}
      className="form_preview_accordion"
      expanded={selectedSections?.includes(nodesData.id)}
      sx={{
        background: selectedSections?.includes(nodesData.id)
          ? renderBgColor(nodesData)
          : "white",
        mb: "5px",
        borderRadius: "10px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            onClick={(e) => {
              e.stopPropagation();
              handleChange(nodesData);
            }}
          />
        }
        aria-controls="panel1bh-content"
        id={nodesData.id}
        onClick={(e) => e.stopPropagation()} // Prevent the summary from toggling the state
      >
        <Box key={nodesData.id} sx={{ width: "100%" }}>
          <Typography
            sx={{ mb: selectedSections?.includes(nodesData.id) ? "10px" : 0 }}
          >
            {`${nodesData.id}` || "Node"}
          </Typography>
          {selectedSections?.includes(nodesData.id) && (
            <Typography>{`${nodesData?.data?.nodeTitle}` || "Node"}</Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{renderUiForms(nodesData?.id)}</AccordionDetails>
    </Accordion>
  );

  const renderUiForms = (parentId: string | null) => {
    const filteredNodes = nodes?.filter(
      (node: any) =>
        node.parentNode === parentId ||
        (parentId === null && node.parentNode === "")
    );

    return filteredNodes?.map((nodesData: any) => renderNodeBox(nodesData));
  };

  const renderTooltip = () => {
    return (
      <Box className="jsonViewer" sx={{ p: "10px" }}>
        <Typography sx={{ mb: "10px" }}>
          {selectedSection?.id && (`${selectedSection?.id}` || "Node")}
        </Typography>
        <Typography sx={{ mb: "18px" }}>
          {selectedSection?.id &&
            (`${selectedSection?.data?.nodeTitle}` || "Node")}
        </Typography>

        <ReactJson
          src={selectedSection}
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
        />
      </Box>
    );
  };

  const handleExpandButton = (expandStr: string) => {
    setIsExpandedAll(expandStr);
  };

  return nodes ? (
    <div
      className="formPreview"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ width: "50%", mr: "20px" }}>
        <Box sx={{ py: "16px" }}>
          <Button
            sx={{
              background: isExpandedAll === "expand_all" ? "#2563eb" : "#ccc",
              color: isExpandedAll === "expand_all" ? "white" : "#888",
              mx: "10px",
              px: "20px",
              borderRadius: "30px",
              textTransform: "none",
              "&:hover": {
                background: "transparent",
                color: "#000",
              },
            }}
            onClick={() => handleExpandButton("expand_all")}
          >
            Expand All
          </Button>
          <Button
            sx={{
              background: isExpandedAll === "collapse_all" ? "#2563eb" : "#ccc",
              color: isExpandedAll === "collapse_all" ? "white" : "#888",
              mx: "10px",
              px: "20px",
              borderRadius: "30px",
              textTransform: "none",
              "&:hover": {
                background: "transparent",
                color: "#000",
              },
            }}
            onClick={() => handleExpandButton("collapse_all")}
          >
            Collapse All
          </Button>
        </Box>
        {renderUiForms(null)}
      </Box>
      <Box
        sx={{
          width: "49%",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          maxHeight: "calc(100vh - 230px)",
          overflowY: "scroll",
          position: "sticky",
          top: "0px",
        }}
      >
        {renderTooltip()}
      </Box>
    </div>
  ) : (
    <div>No records found</div>
  );
};

export default FormPreview;
