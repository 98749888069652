type ResultYorN = "Y" | "N";

interface InputValues {
  [key: string]: any;
}

interface OutputValues {
  [key: string]: string;
}

const r12KeyArray = [
  "DIS851",
  "DIS852",
  "DIS853",
  "DIS854",
  "DIS855",
  "DIS856",
  "DIS857",
  "DIS858",
  "DIS859",
  "DIS860",
  "DIS861",
  "DIS862",
  "DIS863",
  "DIS864",
  "DIS865",
  "DIS866",
  "DIS867",
  "DIS868",
  "DIS869",
  "DIS870",
  "DIS871",
  "DIS872",
  "DIS873",
  "DIS874",
  "DIS875",
  "DIS876",
  "DIS877",
  "DIS878",
  "DIS879",
  "DIS880",
  "DIS881",
  "DIS882",
  "DIS883",
  "DIS884",
  "DIS885",
  "DIS886",
  "DIS887",
  "DIS888",
  "DIS889",
  "DIS890",
  "DIS891",
  "DIS892",
  "DIS893",
  "DIS894",
  "DIS895",
  "DIS896",
  "DIS897",
  "DIS898",
  "DIS899",
  "DIS900",
  "DIS901",
  "DIS902",
  "DIS903",
  "DIS904",
  "DIS905",
  "DIS906",
  "DIS907",
  "DIS908",
  "DIS909",
  "DIS910",
  "DIS911",
  "DIS912",
  "DIS913",
  "DIS914",
  "DIS915",
  "DIS916",
  "DIS917",
  "DIS924",
  "DIS925",
  "DIS928",
  "DIS929",
  "DIS931",
  "DIS932",
  "DIS933",
  "DIS934",
  "DIS935",
  "DIS936",
  "DIS937",
  "DIS938",
  "DIS939",
  "DIS940",
  "DIS941",
  "DIS942",
  "DIS943",
  "DIS944",
  "DIS945",
  "DIS946",
  "DIS947",
  "DIS948",
  "DIS949",
  "DIS950",
  "DIS951",
];

function calculateRules(inputValues: InputValues): OutputValues {
  return {
    R1: calculateR1(inputValues),
    R2: calculateR2(inputValues),
    R3: calculateR3(inputValues),
    R4: calculateR4(inputValues),
    R5: calculateR5(inputValues),
    R6: calculateR6(inputValues),
    R7: calculateR7(inputValues),
    R8: calculateR8(inputValues),
    R9: calculateR9(inputValues),
    R10: calculateR10(inputValues),
    R11: calculateR11(inputValues),
    R12: calculateR12(inputValues),
    R17: calculateR17(inputValues),
    R18: calculateR18(inputValues),
    R19: calculateR19(inputValues),
    R20: calculateR20(inputValues),
    R21: calculateR21(inputValues),
    R22: calculateR22(inputValues),
    R23: calculateR23(inputValues),
    R24: calculateR24(inputValues),
    R25: calculateR25(inputValues),
    R26: calculateR26(inputValues),
    R27: calculateR27(inputValues),
    R28: calculateR28(inputValues),
    R29: calculateR29(inputValues),
    R30: calculateR30(inputValues),
    R31: calculateR31(inputValues),
    R32: calculateR32(inputValues),
    R33: calculateR33(inputValues),
    R34: calculateR34(inputValues),
    R35: calculateR35(inputValues),
    R36: calculateR36(inputValues),
  };
}

function calculateR1(input: InputValues): ResultYorN {
  //R1	Has Children	GTKY27 >0
  return getResult(getNumber("GTKY27", input) > 0);
}

function calculateR2(input: InputValues): ResultYorN {
  //R2	Other names used applicant	PI166 == "Y"
  return getResult(input["PI166"] === "Y");
}

function calculateR3(input: InputValues): ResultYorN {
  //R3	Other names used USC	PI315 == "Y"
  return getResult(input["PI315"] === "Y");
}

function calculateR4(input: InputValues): ResultYorN {
  //R4	Currently Living together	GTKY26 == "IsLivingtogether"
  // multiselect
  return getResult(
    input["GTKY26"] &&
      Array.isArray(input["GTKY26"]) &&
      valueExistsInArray(input["GTKY26"], "IsLivingtogether")
  );
}

function calculateR5(input: InputValues): ResultYorN {
  //R5	Don’t currently Live together but did live together once	(GTKY24 == "N" AND GTKY28 == "Y")
  return getResult(input["GTKY24"] === "N" && input["GTKY28"] === "Y");
}

function calculateR6(input: InputValues): ResultYorN {
  //R6	Previously Married Applicant	GTKY26 == "APS"
  // multiselect
  return getResult(
    input["GTKY26"] &&
      Array.isArray(input["GTKY26"]) &&
      valueExistsInArray(input["GTKY26"], "APS")
  );
}

function calculateR7(input: InputValues): ResultYorN {
  //R7	Previously Married USC	GTKY26 == "UPS"
  // multiselect
  return getResult(
    input["GTKY26"] &&
      Array.isArray(input["GTKY26"]) &&
      valueExistsInArray(input["GTKY26"], "UPS")
  );
}

function calculateR8(input: InputValues): ResultYorN {
  //R8	Employed -USC	EIN624c =="Y" OR EIN624d =="Y"
  return getResult(input["EIN624c"] === "Y" || input["EIN624d"] === "Y");
}

function calculateR9(input: InputValues): ResultYorN {
  //R9	Using Applicant Income	income_limit_flag>1 AND FINF825>0
  return getResult(
    getNumber("income_limit_flag", input) > 1 && getNumber("FINF825", input) > 0
  );
}

function calculateR10(input: InputValues): ResultYorN {
  //R10	Using Assets of USC	income_limit_flag>2 and FINF828d>0
  return getResult(
    getNumber("income_limit_flag", input) > 2 &&
      getNumber("FINF828d", input) > 0
  );
}

function calculateR11(input: InputValues): ResultYorN {
  //R11	Using Assets of Applicant	income_limit_flag==4 and FINF828e>0
  return getResult(
    getNumber("income_limit_flag", input) === 4 &&
      getNumber("FINF828e", input) > 0
  );
}

function calculateR12(input: InputValues): ResultYorN {
  //R12	USC sponosored someone	IT381=="Y" AND IT382>0
  return getResult(input["IT381"] === "Y" && getNumber("IT382", input) > 0);
}

function calculateR17(input: InputValues): ResultYorN {
  //R17	Immigration Status Changed	IT412a == "N"
  return getResult(input["IT412a"] === "N");
}

function calculateR18(input: InputValues): ResultYorN {
  //R18	Number of dependents different or Wiered Household	(FINF809 =="N" AND FINF812e>0) OR FINF812d !=  FINF824b
  return getResult(
    (input["FINF809"] === "N" && getNumber("FINF812e", input) > 0) ||
      input["FINF812d"] !== input["FINF824b"]
  );
}

function calculateR19(input: InputValues): ResultYorN {
  //R19	Taxes filing flag is N	FINF810a == "N"
  return getResult(input["FINF810a"] === "N");
}

function calculateR20(input: InputValues): ResultYorN {
  //R20	elected to attach 3 years worth of returns	FINF810b == "Y"
  return getResult(input["FINF810b"] === "Y");
}

function calculateR21(input: InputValues): ResultYorN {
  //R21	Applicant has been part of organizations	DIS829 == "Y"
  return getResult(input["DIS829"] === "Y");
}

function calculateR22(input: InputValues): ResultYorN {
  //R22	Immigration Declarations Failed	If any of this array =="Y" array: DIS851,DIS852,DIS853,DIS854,DIS855,DIS856,DIS857,DIS858,DIS859,DIS860,DIS861,DIS862,DIS863,DIS864,DIS865,DIS866,DIS867,DIS868,DIS869,DIS870,DIS871,DIS872,DIS873,DIS874,DIS875,DIS876,DIS877,DIS878,DIS879,DIS880,DIS881,DIS882,DIS883,DIS884,DIS885,DIS886,DIS887,DIS888,DIS889,DIS890,DIS891,DIS892,DIS893,DIS894,DIS895,DIS896,DIS897,DIS898,DIS899,DIS900,DIS901,DIS902,DIS903,DIS904,DIS905,DIS906,DIS907,DIS908,DIS909,DIS910,DIS911,DIS912,DIS913,DIS914,DIS915,DIS916,DIS917,DIS924,DIS925,DIS928,DIS929,DIS931,DIS932,DIS933,DIS934,DIS935,DIS936,DIS937,DIS938,DIS939,DIS940,DIS941,DIS942,DIS943,DIS944,DIS945,DIS946,DIS947,DIS948,DIS949,DIS950,DIS951

  let evalResult: boolean = false;

  for (let key in r12KeyArray) {
    if (input[key] === "Y") {
      evalResult = true;
      break;
    }
  }

  return getResult(evalResult);
}

function calculateR23(input: InputValues): ResultYorN {
  //R23	Interpreter used- applicant	MISC959a =="Y"
  return getResult(input["MISC959a"] === "Y");
}

function calculateR24(input: InputValues): ResultYorN {
  //R24	Preparer used extends- applicant	MISC959b =="Y" AND MISC961 =="N" AND MISC960 == "Y"
  return getResult(
    input["MISC959b"] === "Y" &&
      input["MISC961"] === "N" &&
      input["MISC960"] === "Y"
  );
}

function calculateR25(input: InputValues): ResultYorN {
  //R25	interpreter used- USC	MISC995a =="Y"
  return getResult(input["MISC995a"] === "Y");
}

function calculateR26(input: InputValues): ResultYorN {
  //R26	Preparer used extends- USC	MISC995b =="Y" AND MISC997 =="N" AND MISC996 == "Y"
  return getResult(
    input["MISC995b"] === "Y" &&
      input["MISC997"] === "N" &&
      input["MISC996"] === "Y"
  );
}

function calculateR27(input: InputValues): ResultYorN {
  //R27	USC has filed taxes for recent year	(FINF810 == 1)
  // multiselect
  return getResult(
    input["FINF810"] &&
      Array.isArray(input["FINF810"]) &&
      valueExistsInArray(input["FINF810"], "1")
  );
}

function calculateR28(input: InputValues): ResultYorN {
  //R28	I-130 has been filed previously	GTKY24 == "Y"
  return getResult(input["GTKY24"] === "Y");
}

function calculateR29(input: InputValues): ResultYorN {
  //R29	i131 yes or i76yes	GTKY25 == I765Yes OR GTKY25 == I131Yes OR GTKY25 == AllYes
  // multiselect gtky25

  return getResult(
    input["GTKY25"] &&
      Array.isArray(input["GTKY25"]) &&
      (valueExistsInArray(input["GTKY25"], "I765Yes") ||
        valueExistsInArray(input["GTKY25"], "I131Yes") ||
        valueExistsInArray(input["GTKY25"], "AllYes"))
  );
}

function calculateR30(input: InputValues): ResultYorN {
  //R30	I130 not yet filed	GTKY24 =="N"
  return getResult(input["GTKY24"] === "N");
}

function calculateR31(input: InputValues): ResultYorN {
  //R31	is naturalized citizen	IT390 == "N"
  return getResult(input["IT390"] === "N");
}

function calculateR32(input: InputValues): ResultYorN {
  //R32	is adopted	(IT390 == "P" AND GTKY24 == "N" AND IT392 == "Y")
  return getResult(
    input["IT390"] === "P" && input["GTKY24"] === "N" && input["IT392"] === "Y"
  );
}

function calculateR33(input: InputValues): ResultYorN {
  //R33	is armed forces USC	GTKY65 =="Y"
  return getResult(input["GTKY65"] === "Y");
}

function calculateR34(input: InputValues): ResultYorN {
  //R34	Has social security- Applicant	FINF821 == "Y"
  return getResult(input["FINF821"] === "Y");
}

function calculateR35(input: InputValues): ResultYorN {
  //R35	is not naturalized	IT390 != "N"
  return getResult(input["IT390"] !== "N");
}

function calculateR36(input: InputValues): ResultYorN {
  //R36	USC Income Suffecient	income_limit_flag == 1
  return getResult(getNumber("income_limit_flag", input) === 1);
}

function getResult(evalStatement: boolean): "Y" | "N" {
  return evalStatement ? "Y" : "N";
}

function getNumber(key: string, input: InputValues, defaultVal: number = 0) {
  let value: number = input[key] ? +input[key] : defaultVal;

  if (isNaN(value)) {
    value = defaultVal;
  }

  return value;
}

function valueExistsInArray(inputArr: string[], value: string): boolean {
  for (let i = 0; i < inputArr.length; i++) {
    if (inputArr[i].toLowerCase() === value.toLowerCase()) {
      return true;
      break;
    }
  }

  return false;
}

// You might still want to export the function in case you'll use it elsewhere.
export { calculateRules };
