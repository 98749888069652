import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { notificationClear } from "../../store/slices/notificationSlice";

const SnackBarComponent = () => {
  const dispatch: any = useAppDispatch();
  const [show, setShow] = useState(false);
  const status = useAppSelector(
    (state: any) => state.notificationReducer.status
  );
  const message = useAppSelector(
    (state: any) => state.notificationReducer.message
  );
  useEffect(() => {
    if (status === true || status === false) {
      setShow(true);
    }

    setTimeout(() => {
      setShow(false);
      dispatch(notificationClear());
    }, 60000);
  }, [status]);

  const clearNotification = () => {
    dispatch(notificationClear());
  };

  return (
    <>
      {show && status !== null && (
        <div
          className={
            (status
              ? "bg-green-100 text-green-700 border-green-400"
              : "bg-red-100 text-red-700 border-red-400") +
            " border   px-4 py-3 rounded  right-5 top-5 pr-10 fixed z-[1400]"
          }
          role="alert"
        >
          <strong className="font-bold mr-4">
            {status ? "Success" : "Error Detected"}
          </strong>
          <br />
          <span className="block sm:inline">{message}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg
              className={
                (status ? "text-green-500" : "text-red-500") +
                "fill-current h-6 w-6 "
              }
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              onClick={clearNotification}
            >
              <title>Close</title>
              <path
                fill="#bbb"
                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
              />
            </svg>
          </span>
        </div>
      )}
    </>
  );
};

export default SnackBarComponent;
