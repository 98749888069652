import { Navigate } from "react-router";

function PublicRoute(props: any) {
  const { children } = props;
  const accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;
  const userData = localStorage.getItem("userData")
    ? localStorage.getItem("userData")
    : null;
  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);

  if (bootstrapData) {
    if (!bootstrapData?.isVerified) {
      return <Navigate to="/verify" />;
    }
    return accessToken && userData ? <Navigate to="/home" /> : children;
  }
  return children;
}

export default PublicRoute;
