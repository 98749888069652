import { useEffect, useState } from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress} from "@mui/material";
import { useAppDispatch } from "../../store/store";
import { deleteUiUserDataShareScope } from "../../store/thunk/refreshThunk";

interface EmulateUserData {
  expirationDtUtc: Date;
  id: number;
  scope: string;
  sharedWithUserEmail: string;
  sharedWithUserId: number;
  userEmail: string;
  userId: number;
}


const DeleteUserEmulation = (props: any) => {
  const { isDeletePopup, setIsDelete, currUser, uiUserDataShareScope } = props;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<any>(false);
  const [currentUserData, setCurrentUserData] = useState<EmulateUserData>();
  
  useEffect(() => {
    const tempEmulateUserData = uiUserDataShareScope?.filter((userData: EmulateUserData) => userData?.id === currUser);
    setCurrentUserData(tempEmulateUserData[0]);
  }, [currUser]);

  const handleDelete = () => {
    const _request = {
      id: currUser,
    };
    setLoading(true);
    dispatch(deleteUiUserDataShareScope(_request)).then((res: any) => {
      setLoading(false);
      setIsDelete(false);
    });
  };

  return (
    <>
      <Dialog
        open={isDeletePopup}
        onClose={() => setIsDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-delete-user"
      >
        <DialogTitle id="alert-dialog-title">Delete Emulation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to delete ${currentUserData?.userEmail} emulation?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDelete(false)}>No</Button>
          <Button onClick={handleDelete} autoFocus>
            {loading ? (
              <CircularProgress size={16} sx={{ color: "#fff" }} />
            ) : (
              "Yes"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteUserEmulation;
