import { checkDependantQuestionRequired } from "../../helper/service";

export const validate = () => {
  return "";
};
const ValidateFormJson = (props: any) => {
  const { formData, list, locked } = props;

  let error: any = {};

  if (locked) {
    return error;
  }

  list?.map((question: any, index: any) => {
    if (
      checkDependantQuestionRequired(question, formData) &&
      !formData[question.id]
    ) {
      if (question?.data?.properties?.type?.toLowerCase() == "boolean") {
        error = { ...error, [question.id]: "Please select any  option!" };
      } else if (question?.data?.properties?.type?.toLowerCase() !== "text") {
        error = { ...error, [question.id]: "This field is Required!" };
      }
    } else if (
      checkDependantQuestionRequired(question, formData) &&
      (question?.data?.properties.type?.toLowerCase() === "dropdown" ||
        question?.data?.properties.type?.toLowerCase() === "multiselect") &&
      Object.keys(formData[question.id]).length === 0
    ) {
      error = { ...error, [question.id]: "Please Select Any Options!" };
    } else {
      let validation = question?.data?.properties?.properties?.validations;

      if (validation && validation.length) {
        validation.map((val: any) => {
          if (val?.type === "regex") {
            var re = new RegExp(val?.value);
            if (!re.test(formData[question.id] || "")) {
              error = {
                ...error,
                [question.id]: val?.message || "Please enter a valid value!",
              };
            }
          }

          if (val.type === "length") {
            if (
              formData[question.id] &&
              formData[question.id].length > val.value
            ) {
              error = {
                ...error,
                [question.id]: val?.message || `Max Length is ${val.value}.`,
              };
            }
          }
        });
      }
    }
  });

  return error;
};
export default ValidateFormJson;
