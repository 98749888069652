type LogLevel = 'info' | 'warn' | 'error' | 'debug';

const baseURL = window.API_URL;

interface Log {
  timestampUtc: Date;
  detailsJson: string;
  level: LogLevel;
  component: string;
}

export default class Logger {
  private logs: Log[] = [];
  private timer!: NodeJS.Timeout;

  constructor() {
    // Start the 2-minute timer to send logs
    this.resetTimer();
  }

  // Method to add a log object to the array
  public log(log: Omit<Log, 'timestampUtc'>): void {
    const newLog: Log = {
      ...log,
      timestampUtc: new Date(), // Add timestamp if not provided
    };
    this.logs.push(newLog);

    // If logs reach 50, send them immediately
    if (this.logs.length >= 50) {
      this.sendLogs();
    }
  }

  // Method to retrieve all logs
  public getLogs(): Log[] {
    return this.logs;
  }

  // Method to retrieve logs by level (e.g., 'info', 'error')
  public getLogsByLevel(level: LogLevel): Log[] {
    return this.logs.filter(log => log.level === level);
  }

  // Method to send logs to the API (fire and forget)
  private sendLogs(): void {
    if (this.logs.length === 0) return; // Do not send if logs are empty

    const logsToSend = [...this.logs]; // Make a copy of the logs
    this.logs = []; // Empty out the logs array

    fetch(`${baseURL}/logger/ui/submit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ uiLogs: logsToSend })
    }).then(() => {
      this.resetTimer(); // Reset the timer after successful send
    }).catch((error) => {
      console.error('Failed to send logs:', error);
      this.logs = this.logs.concat(logsToSend); // Append the copied logs back to the array if the send fails
    });
  }

  // Method to reset the timer
  private resetTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.sendLogs();
    }, 2 * 60 * 1000); // 2 minutes
  }
}

// Example usage
// const logger = new Logger();

// logger.log({ level: 'info', detailsJson: '{"message": "Application started"}', component: 'App' });
// logger.log({ level: 'error', detailsJson: '{"message": "An error occurred", "errorCode": 500}', component: 'App' });

// console.log(logger.getLogs()); // Prints all logs
// console.log(logger.getLogsByLevel('error')); // Prints only error logs
