import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { useAppSelector } from "../../store/store";
import {
  addUserForm,
  fetchDocuments,
  fetchDocumentsOfClient,
  getSingleForm,
} from "../../store/thunk/formThunk";
import {
  setIsCaseType,
  setUploadedDocuments,
} from "../../store/slices/flowFormSlice";
import {
  anonymousUser,
  logErrorFunction,
  logFunction,
} from "../../helper/service";
import PayAndDownloadButton from "../payAndDownload/payAndDownloadButton";
import SignInSignUpButton from "../payAndDownload/signInSignUpButton";
import BackDropLoader from "../../components/loader/backDropLoader";
import dotnetApiClient from "../../config/dotnetApiClient";
import axios from "axios";
import "./ChatPage.css";
import ChatDialogComponent from "./ChatDialogComponent";
import { getLogoForClient } from "../../store/thunk/billingThunk";
import { setLoading } from "../../store/slices/notificationSlice";
import { AppDispatch } from "../../store/store";
import { getProgressData } from "../../store/thunk/documentThunk";

interface IUserDocument {
  udId: string;
  userId: string;
  documentSlug: string;
  dateCreated: string;
  dateDeleted: string;
  originalFileName: string;
  title: string;
  description: string; // Changed to string for description
}

export default function Home() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { getSignleFormData, uiFormPayload, uploadedDocuments } =
    useAppSelector((state: any) => state?.formReducer);
  const { progressData } = useAppSelector(
    (state: any) => state?.documentReducer
  );
  const { loading } = useAppSelector((state: any) => state.notificationReducer);
  const [allDatas, setAllDatas] = React.useState<any>({});
  const [sectionLists, setSectionLists] = React.useState<any>([]);
  const [locked, setLocked] = useState<any>(false);
  const [paymentLoader, setPaymentLoader] = useState<boolean>(false);
  const [isIframe, setIsIframe] = useState<boolean>(window.self !== window.top);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [apiCalled, setApiCalled] = useState(false); // State to track if API is called
  const [uiFlowID, setUiFlowID] = useState<any>();
  const [caseTypeDetails, setCaseTypeDetails] = useState<any>({});

  //for user documents
  const accessToken = localStorage.getItem("accessToken") || null;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState("");
  const [userId, setUserId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const allowedFileTypes = ["application/pdf", "image/png", "image/jpeg"];
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [selectedDocument, setSelectedDocument] =
    React.useState<IUserDocument | null>(null);
  const [downloadingDocId, setDownloadingDocId] = useState<string | null>(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatDetails, setChatDetails] = useState<any>(null);
  const toggleChatWindow = () => {
    setIsChatOpen(!isChatOpen);
    setUnreadCount(0);
  };

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);
  const roleData: any = localStorage.getItem("role");

  const [isChatLoaded, setIsChatLoaded] = useState<boolean>(true);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const uiFlowJsonId = localStorage.getItem("uiFlowJsonId");
  const [isCaseTypeOpen, setIsCaseTypeOpen] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);


  const isCaseType = localStorage.getItem("isCaseType")
    ? localStorage.getItem("isCaseType")
    : null;

  useEffect(() => {
    if (isCaseType) {
      setIsCaseTypeOpen(isCaseType);
    }
    setIsLoaded(true);
  }, [isCaseType]);

  const localCaseTypeDetails = JSON.parse(
    localStorage.getItem("caseTypeDetails") || "{}"
  );

  const handleLogout = () => {
    const isIframe = window.self !== window.top;

    let redirectUrl = "";
    localStorage.clear();
    sessionStorage.clear();

    if (isIframe) {
      redirectUrl = window.IFRAME_REDIRECT_URL;
      if (window.top) {
        window.top.location.href = redirectUrl;
      }
    } else {
      redirectUrl = window.CURRENT_HOST;
    }
  };

  const getMessageUnReadCount = async (request_data: any) => {
    if (!accessToken) {
      logErrorFunction("Token is not available");
      return;
    }

    const requestedData = {
      withUserId: bootstrapData?.createdBy,
      // channelIdentifier: request_data?.streamChannelId
    };
    logFunction(requestedData);
    try {
      const response = await dotnetApiClient().post(
        "/chat/message/info",
        requestedData
      );
      const data = response.data;
      if (data?.unreadMessageCount) {
        setIsChatLoaded(true);

        setUnreadCount(data?.unreadMessageCount);
      }
    } catch (error: any) {
      logErrorFunction("Error fetching clients:" + error);

      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
    } finally {
      setIsChatLoaded(true);
    }
  };

  useEffect(() => {
    if (
      isIframe &&
      bootstrapData?.createdBy &&
      !sessionStorage.getItem("Usapath-Emulate-UserId")
    ) {
      const apiCall = async () => {
        try {
          const requestData = { withUserId: bootstrapData?.createdBy };
          const response = await dotnetApiClient().post("/chat", requestData);
          const data = response.data;

          let tempChatDetails: any = {
            apiKey: data?.apiKey,
            userId: data?.streamUsername,
            channelId: data?.streamChannelId,
            userToken: data?.userToken,
            userData: { id: data?.streamUsername },
          };

          setChatDetails(tempChatDetails);
          getMessageUnReadCount(data);
        } catch (error: any) {
          logErrorFunction("Error fetching data:" + error);

          if (
            error?.response?.status === 401 ||
            error?.response?.status === 406
          ) {
            handleLogout();
          }
        }
      };

      apiCall();
    }
  }, []);

  useEffect(() => {
    if (isIframe && bootstrapData?.refreshReady) {
      dispatch(getLogoForClient());
    }
  }, [bootstrapData?.refreshReady]);

  useEffect(() => {
    const bootstrapData = localStorage.getItem("bootstrapData");
    if (bootstrapData) {
      const parsedData = JSON.parse(bootstrapData);
      setUserId(parsedData.id);
    }
  }, []);

  useEffect(() => {
    if (uiFormPayload?.ui_form_lock_state) {
      setLocked(uiFormPayload?.ui_form_lock_state);
      localStorage.setItem("lockedApp", uiFormPayload?.ui_form_lock_state);
    } else {
      setLocked(false);
      localStorage.setItem("lockedApp", "false");
    }
  }, [uiFormPayload]);

  useEffect(() => {
    if (getSignleFormData?.flowJson) {
      const fetchData = async () => {
        const nodeData = JSON.parse(getSignleFormData?.flowJson);
        let getJsonData: any = await convertIntoUiJson(nodeData?.nodes);
        const mergedObj = nodeData?.nodes?.flatMap((flowJsonData: any) =>
          getJsonData
            ?.filter(
              (getJsonDataItem: any) => flowJsonData.id === getJsonDataItem.id
            )
            .map((matchingItem: any) => ({ ...matchingItem, ...flowJsonData }))
        );
        setAllDatas(mergedObj);
        const sectionData = nodeData?.nodes?.filter(
          (obj: any) => obj?.data?.nodeType === "section"
        );

        if (sectionData?.length > 0) {
          setSectionLists(sectionData);
        } else {
          setSectionLists([]);
        }
      };
      fetchData();
    }
  }, [getSignleFormData]);

  useEffect(() => {
    const isEligibilityFilled = progressData?.find(
      (section: any) => section?.sectionKey === "General_Eligibility"
    );

    if (isCaseTypeOpen !== "false" && isCaseTypeOpen !== "") {
      if (!isIframe) {
        if (isEligibilityFilled && isEligibilityFilled?.percentComplete === 0) {
          navigate("/eligibility-check/Eligibility%20Quiz");
        }
      }
    }
  }, [progressData, isCaseTypeOpen]);

  const convertIntoUiJson = (jsonData: any) => {
    try {
      let orderCounts: any = [];
      return (
        !!jsonData &&
        jsonData.length > 0 &&
        jsonData.map((val: any) => {
          let childList = jsonData
            .filter((row: any) => val.id === row.parentNode)
            .map((row: any) => row.id);
          let order = orderCounts.filter((row: any) => val.parentNode === row);
          orderCounts.push(val.parentNode);
          return {
            id: val.id,
            parentNode: val?.parentNode,
            order: (!!order?.length ? order?.length : 0) + 1,
            children: childList,
          };
        })
      );
    } catch (error) {
      alert("json is not correct.");
    }
  };

  const onStart = () => {
    const section = sectionLists.filter(
      (val: any) =>
        val?.id == "General_Eligibility" ||
        val?.id == "I864A - Household member contract"
    );

    navigate(`/eligibility-check/${encodeURI(section[0]?.data?.nodeTitle)}`);
  };

  const onResumeClick = () => {
    const eligibilityProgress = progressData.filter(
      (val: any) =>
        (val.sectionKey == "General_Eligibility" &&
          val.percentComplete >= 88) ||
        (val.sectionKey == "I864A - Household member contract" &&
          val.percentComplete >= 5)
    );

    if (eligibilityProgress.length > 0) {
      const sectionPercentag = sectionLists
        .filter((val: any) => val?.id !== "General_Eligibility")
        .map((section: any) => {
          const matchingItem2 = progressData.find(
            (progress: any) => progress.sectionKey === section.id
          );
          return {
            ...section,
            percentComplete: matchingItem2?.percentComplete || 0,
            missingFlowIds: matchingItem2?.missingFlowIds || [],
            filledFlowIds: matchingItem2?.filledFlowIds || [],
          };
        });

      const filteredSection = sectionPercentag.filter(
        (val: any) => val?.percentComplete < 100
      );

      const activeSectionId = filteredSection[0].id;
      const activeSectionSummary = allDatas?.filter(
        (obj: any) => obj?.parentNode === activeSectionId
      );

      const activeSummaryIndex = activeSectionSummary.findIndex(
        (val: any) => val?.data?.nodeTitle == "Summary"
      );

      if (filteredSection[0]?.id !== "General_Eligibility") {
        navigate(`/eligibility-check/${filteredSection[0]?.data?.nodeTitle}`);
      } else {
        navigate(
          `/eligibility-check/${filteredSection[0]?.data?.nodeTitle}?summary=${activeSectionSummary[activeSummaryIndex]?.id}`
        );
      }
    } else {
      onStart();
    }
  };

  const handleLocking = async (flag = false) => {
    const tempUnSavedFormValues: any =
      sessionStorage.getItem("unSavedFormValues");
    const tempUpdatedFormValues: any =
      sessionStorage.getItem("updatedFormValues");

    let updatedFormValues = JSON.parse(tempUpdatedFormValues);
    let unSavedFormValues = JSON.parse(tempUnSavedFormValues);

    updatedFormValues = {
      ...updatedFormValues,
      ["ui_form_lock_state"]: flag,
    };

    await dispatch(
      addUserForm({
        form_data: JSON.stringify(updatedFormValues),
      })
    ).then((res: any) => {
      if (res?.payload?.data) {
        sessionStorage.setItem(
          "unSavedFormValues",
          JSON.stringify({ ...unSavedFormValues, ["ui_form_lock_state"]: flag })
        );
        localStorage.setItem("lockedApp", JSON.stringify(flag));
        setLocked(flag);
        window.location.reload();
      }
    });
  };

  const getActionButton = () => {
    if (
      progressData &&
      progressData?.length &&
      sectionLists &&
      sectionLists?.length &&
      !anonymousUser()
    ) {
      if (
        progressData?.filter(
          (obj: any) =>
            (obj?.percentComplete == 0 &&
              obj?.sectionKey == "General_Eligibility") ||
            (obj?.percentComplete <= 4 &&
              obj?.sectionKey == "I864A - Household member contract")
        )?.length
      ) {
        return (
          <Button
            variant="contained"
            sx={{
              background: "#3260e4",
              boxShadow: "none",
              "&:hover": {
                background: "#3260e4",
              },
            }}
            onClick={onStart}
            disabled={paymentLoader}
          >
            Start
          </Button>
        );
      } else if (
        progressData?.filter(
          (obj: any) =>
            (obj?.percentComplete > 0 &&
              obj?.sectionKey == "General_Eligibility") ||
            (obj?.percentComplete > 4 &&
              obj?.sectionKey == "I864A - Household member contract")
        )?.length
      ) {
        return (
          <Button
            variant="contained"
            sx={{
              background: "#2563eb",
              boxShadow: "none",
              "&:hover": {
                background: "#3260e4",
              },
            }}
            onClick={onResumeClick}
            disabled={paymentLoader}
          >
            Resume
          </Button>
        );
      } else if (
        progressData &&
        sectionLists &&
        progressData?.filter((obj: any) => obj?.percentComplete == 100)
          ?.length == sectionLists?.length
      ) {
        return (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#2dac5d",
            }}
            variant="caption"
            component="div"
            fontFamily="Sen"
          >
            Completed
          </Typography>
        );
      }
    } else if (anonymousUser()) {
      return (
        <Button
          variant="contained"
          sx={{
            background: "#3260e4",
            boxShadow: "none",
            "&:hover": {
              background: "#3260e4",
            },
          }}
          onClick={onStart}
          disabled={paymentLoader}
        >
          Start
        </Button>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (roleData && !apiCalled) {
      if (roleData === "lawyer") {
        if (userId) {
          dispatch(fetchDocuments(userId));
          setApiCalled(true);
        }
      }
      if (roleData === "client") {
        dispatch(fetchDocumentsOfClient());
        setApiCalled(true);
      }
    }
  }, [userId, roleData, apiCalled]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (validateFile(file)) {
        setFile(file);
      }
    }
  };

  const validateFile = (file: File) => {
    const maxFileSize = 30 * 1024 * 1024; // 30MB

    if (!allowedFileTypes.includes(file.type)) {
      setError("Invalid file type. Allowed types: PDF, PNG, JPG, JPEG.");
      return false;
    }
    if (file.size > maxFileSize) {
      setError("File size exceeds 30MB limit.");
      return false;
    }
    setError("");
    return true;
  };

  const handleOpenConfirmDialog = (doc: IUserDocument) => {
    setSelectedDocument(doc);
    setOpenConfirmDialog(true);
  };

  const handleUpload = async () => {
    if (!title || !description || !file) {
      setError("Please fill out all fields and select a valid file.");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("Title", title);
    formData.append("Description", description);
    formData.append("Document", file);

    try {
      const response = await dotnetApiClient().post(
        "/UserDocument/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const document = response.data;
      const newDoc: IUserDocument = {
        udId: document.udId,
        userId: document.userId,
        documentSlug: document.documentSlug,
        dateCreated: document.dateCreated,
        dateDeleted: document.dateDeleted,
        originalFileName: document.originalFileName,
        title: document.title,
        description: document.description,
      };
      const updatedDocuments = [...uploadedDocuments, newDoc];
      setUploadedDocuments(updatedDocuments);
      setTitle("");
      setDescription("");
      setFile(null);

      if (roleData) {
        if (roleData === "lawyer") {
          if (userId) {
            dispatch(fetchDocuments(userId));
            setApiCalled(true);
          }
        }
        if (roleData === "client") {
          dispatch(fetchDocumentsOfClient());
          setApiCalled(true);
        }
      }
      handleClose(); // Close the modal
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      if (axios.isAxiosError(error)) {
        // Axios-specific error handling
        if (error.response) {
          // Access Axios response
          const errorMessage = error.response.data.message || "Bad request";
          setError(`Bad request: ${errorMessage}`);
        } else {
          setError("Network error: Unable to connect.");
        }
      } else if (error instanceof Error) {
        // General error handling
        setError(error.message || "An unexpected error occurred.");
      } else {
        setError("An unexpected error occurred.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  function onDownload(doc: IUserDocument) {
    setDownloadingDocId(doc.udId); // Start loading

    var linkUrl: any = null;

    if (roleData) {
      if (roleData === "lawyer") {
        if (userId) {
          linkUrl = `${dotnetApiClient().defaults.baseURL
            }/UserDocument/user/${userId}/preview/${doc.udId}`;
        }
      }
      if (roleData === "client") {
        linkUrl = `${dotnetApiClient().defaults.baseURL}/UserDocument/preview/${doc.udId
          }`;
      }

      fetch(linkUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          Accept: "*/*",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.download = doc.originalFileName; // Specify the filename here
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
          window.URL.revokeObjectURL(url); // Clean up after download
        })
        .catch((error: any) => {
          logErrorFunction(
            "There was a problem with the download request:" + error
          );

          if (
            error?.response?.status === 401 ||
            error?.response?.status === 406
          ) {
            handleLogout();
          }
        })
        .finally(() => {
          setDownloadingDocId(null); // End loading
        });
    }
  }

  const handleConfirmDelete = async () => {
    if (selectedDocument) {
      try {
        await _handleFileDelete(selectedDocument);
        setSelectedDocument(null); // Clear selected document
      } catch (error) {
        logErrorFunction("Error deleting document:" + error);
      }
    }
    setOpenConfirmDialog(false); // Close the dialog
  };

  const handleCancelDelete = () => {
    setSelectedDocument(null); // Clear selected document
    setOpenConfirmDialog(false); // Close the dialog
  };

  const _handleFileDelete = async (file: IUserDocument) => {
    if (file) {
      setIsLoading(true);
      try {
        await dotnetApiClient(true).delete(`/UserDocument/${file.udId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const updatedDocuments = uploadedDocuments.filter(
          (doc: IUserDocument) => doc.udId !== file.udId
        );
        dispatch(setUploadedDocuments(updatedDocuments));

        if (roleData) {
          if (roleData === "lawyer") {
            if (userId) {
              dispatch(fetchDocuments(userId));
            }
          }
          if (roleData === "client") {
            dispatch(fetchDocumentsOfClient());
          }
        }

        // dispatch(fetchDocuments(userId))
      } catch (error: any) {
        logErrorFunction("Error deleting document:" + error);

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 406
        ) {
          handleLogout();
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (bootstrapData?.caseTypes?.length === 1) {
      const caseTypeDetails = bootstrapData?.caseTypes?.[0];
      localStorage.setItem("uiFlowJsonId", caseTypeDetails?.id);
      localStorage.setItem("caseTypeDetails", JSON.stringify(caseTypeDetails));
      localStorage.setItem("isCaseType", "true");
      setIsCaseTypeOpen("true");
      dispatch(setLoading(true));
    }
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCaseTypeSelect = (e: any) => {
    e.preventDefault();
    dispatch(getSingleForm({ uiFlowJsonId: uiFlowID }));
    localStorage.setItem("uiFlowJsonId", uiFlowID);
    localStorage.setItem("caseTypeDetails", JSON.stringify(caseTypeDetails));
    localStorage.setItem("isCaseType", "true");
    setIsCaseTypeOpen("true");
    dispatch(setLoading(true));
    // dispatch(getProgressData());
  };

  const shouldDisplayComponent =
    isLoaded &&
    bootstrapData?.caseTypes?.length > 1 &&
    (!isCaseTypeOpen || isCaseTypeOpen === "false");

  return (
    <>
      {loading && <BackDropLoader />}
      <Container maxWidth="lg">
        <Box
          className="custom-scrollbar payment"
          height={"calc(100vh - 133px)"}
          overflow={"auto"}
          style={{ paddingBottom: "100px" }}
        >
          <Paper
            className="homepage"
            sx={{
              border: "1px solid #ddd",
              borderRadius: "20px",
              boxShadow: "none",
              padding: { md: "20px", xs: "10px" },
            }}
          >
            <h1>Applications</h1>
            <Box className="home-application-title md:flex block justify-between">
              <Typography variant="h4">{localCaseTypeDetails?.title}</Typography>
              <Box sx={{ display: "flex", mt: { md: 0, xs: 1 } }}>
                {getActionButton()}
                {!anonymousUser() && (
                  <>
                    <Button
                      variant="outlined"
                      sx={
                        !locked
                          ? { background: "#eff6ff" }
                          : { color: "#9ebaf6 !important" }
                      }
                      onClick={() => handleLocking(true)}
                      disabled={
                        !locked &&
                          !paymentLoader &&
                          sessionStorage.getItem("Usapath-Emulate-UserScope") !==
                          "Readonly"
                          ? false
                          : true
                      }
                    >
                      <LockOutlinedIcon />
                      Lock
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={locked ? false : true}
                      sx={
                        locked
                          ? { background: "#eff6ff" }
                          : { color: "#9ebaf6 !important" }
                      }
                      onClick={() => handleLocking(false)}
                    >
                      <LockOpenOutlinedIcon />
                      Unlock
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Paper>
          <Box>
            <Grid container spacing={2}>
              {!isIframe && anonymousUser() && (
                <Grid item xs={12} md={6}>
                  <Paper
                    sx={{
                      padding: { md: "20px", xs: "10px" },
                      border: "1px solid #ddd",
                      borderRadius: "20px",
                      boxShadow: "none",
                    }}
                  >
                    <h1 style={{ fontWeight: "600", marginBottom: "10px" }}>
                      Billing
                    </h1>
                    <Box sx={{ margin: "10px 0 0" }}>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "20px",
                          mr: 1,
                          textTransform: "capitalize",
                          background: "#2563eb",
                          boxShadow: "none",
                        }}
                        disabled={paymentLoader}
                      >
                        View Invoice
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              )}
              {!isIframe && (
                <Grid item xs={12} md={anonymousUser() ? 12 : 6}>
                  <Paper
                    sx={{
                      padding: { md: "20px", xs: "10px" },
                      border: "1px solid #ddd",
                      borderRadius: "20px",
                      boxShadow: "none",
                      overflowY: "auto",
                      mb: "100px",
                    }}
                  >
                    {anonymousUser() ? (
                      <Box
                        sx={{
                          background: "#eff6fe",
                          borderRadius: "12px",
                          m: "0 auto",
                        }}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <SignInSignUpButton source={"home"} onStart={onStart} />
                      </Box>
                    ) : (
                      <>
                        <h1 style={{ fontWeight: "600", marginBottom: "10px" }}>
                          Pay and Download
                        </h1>
                        <PayAndDownloadButton isBoth={true} />
                      </>
                    )}
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Box>

          <Box>
            {isIframe && (
              <div>
                {/* Chat Bubble Icon */}
                <div className="chat-bubble-icon" onClick={toggleChatWindow}>
                  💬
                  {unreadCount > 0 && (
                    <p className="unread_count_badge">{unreadCount}</p>
                  )}
                </div>
                {/* Chat Window */}
                {isChatOpen && (
                  <div className="chat-window">
                    <div className="chat-window-header">
                      <span>Chat</span>
                      <button
                        className="close-button"
                        onClick={toggleChatWindow}
                      >
                        ✖
                      </button>
                    </div>
                    <div className="chat-window-content">
                      {isChatLoaded ? (
                        chatDetails && (
                          <ChatDialogComponent
                            userId={chatDetails?.userId}
                            apiKey={chatDetails?.apiKey}
                            channelId={chatDetails?.channelId}
                            userToken={chatDetails?.userToken}
                            userData={chatDetails?.userData}
                            bootstrapData={bootstrapData}
                          />
                        )
                      ) : (
                        <Box className="loader">
                          <CircularProgress color="primary" />
                        </Box>
                      )}
                      {/* <p>You will soon be able to chat with your lawyer!</p> */}
                    </div>
                  </div>
                )}
              </div>
            )}
          </Box>

          <Box
            className="custom-scrollbar"
            height={"calc(100vh - 133px)"}
            overflow={"auto"}
            style={{ paddingBottom: "100px" }}
          >
            {isIframe && (
              <main className="flex-1 p-6">
                <Typography variant="h4" component="h1" gutterBottom>
                  Uploaded Documents
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  style={{ marginBottom: "16px" }}
                >
                  Upload New Document
                </Button>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <div className="bg-white p-6 rounded-lg shadow-md mb-6">
                      {uploadedDocuments?.length > 0 ? (
                        <table
                          className="w-full border-collapse"
                          style={{
                            display: "block",
                            overflowX: "scroll",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <thead>
                            <tr className="bg-gray-200">
                              <th className="border-b p-2 text-left">Title</th>
                              <th className="border-b p-2 text-left">
                                Description
                              </th>
                              <th className="border-b p-2 text-left">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {uploadedDocuments.map((doc: any) => (
                              <tr key={doc.udId}>
                                <td className="border-b p-2">{doc.title}</td>
                                <td className="border-b p-2">
                                  {doc.description}
                                </td>
                                <td className="border-b p-2">
                                  <div className="download_btn_container">
                                    <Button
                                      onClick={() => onDownload(doc)}
                                      variant="outlined"
                                      color="primary"
                                      disabled={downloadingDocId === doc.udId}
                                      className="download_client_btn"
                                    >
                                      {downloadingDocId === doc.udId ? (
                                        <CircularProgress size={24} />
                                      ) : (
                                        "Download"
                                      )}
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleOpenConfirmDialog(doc)
                                      }
                                      variant="outlined"
                                      color="secondary"
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <Box>
                          <Typography>No data found</Typography>
                        </Box>
                      )}
                    </div>
                  </>
                )}
              </main>
            )}

            {isIframe && (
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="upload-modal-title"
                aria-describedby="upload-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography
                    id="upload-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Upload New Document
                  </Typography>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    rows={3}
                  />
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ marginBottom: "8px" }}
                  />
                  <Typography
                    color="rgb(218, 59, 56)"
                    style={{ marginBottom: "8px" }}
                  >
                    {error}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" paragraph>
                    Allowed file types: PDF, PNG, JPG, JPEG.
                  </Typography>
                  <Box textAlign="right">
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      style={{ marginRight: "8px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleUpload}
                      color="primary"
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} /> : "Upload"}
                    </Button>
                  </Box>
                </Box>
              </Modal>
            )}
            {isIframe && (
              <Dialog
                open={openConfirmDialog}
                onClose={handleCancelDelete}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
              >
                <DialogTitle id="confirm-dialog-title">
                  Confirm Deletion
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="confirm-dialog-description">
                    Are you sure you want to delete this document? This action
                    cannot be undone.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelDelete} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleConfirmDelete} color="secondary">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Box>
        </Box>
      </Container>
      {shouldDisplayComponent && (
        <div className="case-type-modal">
          <form onSubmit={(e: any) => handleCaseTypeSelect(e)}>
            <div className="bg-light">
              <h2 className="text-center">Select Case Type</h2>
              <div className="row justify-center my-4">
                {bootstrapData?.caseTypes?.map((c: any) => (
                  <label>
                    <input
                      type="radio"
                      name="uiFlowID"
                      className="d-none"
                      value={c?.id}
                      onChange={(e) => {
                        setCaseTypeDetails(c);
                        setUiFlowID(e.target.value);
                      }}
                      checked={uiFlowID === c?.id?.toString()}
                      required
                    />
                    <p className="card m-3" key={c?.id}>
                      {c?.title}
                    </p>
                  </label>
                ))}
              </div>
              <div className="row justify-center">
                <Button variant="contained" type="submit" size="large">
                  SUBMIT
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
