import UserForms from "../../components/userForms";

const Formflow = () => {
  return (
    <div className="md:p-8 p-4">
      <UserForms />
    </div>
  );
};

export default Formflow;
