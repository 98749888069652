import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allDocumentData: [],
  uploadedDocument: [],
  progressData: [],
  summaryList: [],
};

export const documentReducer = createSlice({
  name: "document",
  initialState,
  reducers: {
    setAllDocumentData: (state, action) => ({
      ...state,
      allDocumentData: action.payload,
    }),
    setUploadedDocument: (state, action) => ({
      ...state,
      uploadedDocument: action.payload,
    }),
    setProgressData: (state, action) => ({
      ...state,
      progressData: action.payload,
    }),
    setSummaryAllData: (state, action) => ({
      ...state,
      summaryList: action.payload,
    }),
  },
});

export const { setAllDocumentData, setUploadedDocument, setProgressData, setSummaryAllData } =
  documentReducer.actions;
export default documentReducer.reducer;
