import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { useDispatch, useSelector } from "react-redux";
import notificationReducer from "./slices/notificationSlice";
import authReducer from "./slices/authSlice";
import userReducer from "./slices/userSlice";
import formReducer from "./slices/flowFormSlice";
import documentReducer from "./slices/documentSlice";
import refreshReducer from "./slices/refreshSlice";
import  billingReducer  from "./slices/billingSlice";

const rootReducer = combineReducers({
  notificationReducer,
  authReducer,
  userReducer,
  formReducer,
  documentReducer,
  refreshReducer,
  billingReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});



// export const useAppDispatch = () => useDispatch();
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = useSelector;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

