import { logErrorFunction ,logFunction } from "./service";

interface IncomeValues {
  [key: string]: string;
}

interface PersonIncome {
  [key: string]: number;
}

interface IncomeLimitItem {
  ilId: number;
  householdSize: number;
  state: string;
  income: number;
}

interface OutputValues {
  [key: string]: string;
}

const householdSizeTotalApplicantKey = "FINF812d";
const dependentChildrenApplicantKey = "FINF811a";
const otherDependentApplicantKey = "FINF812b";
const otherHouseholdMemberApplicantKey = "FINF812e";

const sponsorIncome814Key = "FINF814";
const sponsorAsset815Key = "FINF815";
const sponsorAsset816Key = "FINF816";
const sponsorAsset817Key = "FINF817";

const immigrantIncome825Key = "FINF825";
const immigrantAsset826Key = "FINF826";
const immigrantAsset827Key = "FINF827";
const immigrantAsset828Key = "FINF828";

const totalIncomeKey = "FINF828a";
const sponsorTotalAssetKey = "FINF828d";
const immigrantTotalAssetKey = "FINF828e";
const totalAssetKey = "FINF828f";

const finf811Key = "FINF811";
const finf812Key = "FINF812";
const it381Key = "IT381";
const it382Key = "IT382";
const it382aKey = "IT382a";

const stateKey = "PI351";

const incomeLimitFlagKey = "income_limit_flag";
const householdSizeKey = "FINF824b";

// New keys
const totalPrimaryIncomeKey = "total_primary_income";

const usCitizenIncomeKey = "FINF814";
const usCitizenAssetBankKey = "FINF815";
const usCitizenAssetRealEstateKey = "FINF816";
const usCitizenAssetStockKey = "FINF817";

const immigrantIncomeKey = "FINF825";
const immigrantAssetBankKey = "FINF826";
const immigrantAssetRealEstateKey = "FINF827";
const immigrantAssetStockKey = "FINF828";

const presenceOfMemberFlagKey = "Q1I864a";
const householdSizeAdjustmentNeededFlagKey = "I864aHSflag";

const member864aIncomeKey = "Q2I864a";
const member864aAssetBankKey = "Q3I864a";
const member864aAssetRealEstateKey = "Q4I864a";
const member864aAssetStockKey = "Q5I864a";

const presenceOfEligibleSponsorFlagKey = "JSeligflag";

const jsIncomeKey = "Q2JSINCOME";
const jsAssetBankKey = "Q1JSASSETS";
const jsAssetRealEstateKey = "Q2JSASSETS";
const jsAssetStockKey = "Q3JSASSETS";

const presenceOfMemberJS864aFlagKey = "Q1JS864A";
const householdSizeAdjustmentJS864aFlagKey = "JSI864aHSflag";

const js864aIncomeKey = "Q2JS864A";
const js864aAssetBankKey = "Q3JS864A";
const js864aAssetRealEstateKey = "Q4JS864A";
const js864aAssetStockKey = "Q5JS864A";

const usCitizenPriorSponsoredKey = "FINFUSCI";
const usCitizenChildInHouseholdKey = "FINF811";
const usCitizenOtherDependentKey = "FINF812";

const jsMarriageKey = "Q4JSI864a";
const jsPriorSponsoredKey = "Q5JSI864";
const jsChildInHouseKey = "Q6JSI864";
const jsOtherDependentKey = "Q7JSI864";

const usCitizenStateKey = "PI351";
const jsStateKey = "Q7Jointsponsorphysical";

const member864aIncrementCountFlagKey = "Q7I864a";
const js864aIncrementCountFlagKey = "Q7JSI864a";

// Output keys
const outputIncomeLimitFlagKey = "income_limit_flag";
const outputInitialHouseholdSizeKey = "initial_household_size";
const outputAdjustedHouseholdSizeKey = "adjusted_household_size";
const outputJsInitialHouseholdSizeKey = "js_initial_household_size";
const outputJsAdjustedHouseholdSizeKey = "js_adjusted_household_size";
const outputTotalPrimaryIncomeKey = "total_primary_income";
const outputTotalHouseIncomeKey = "total_house_income";
const outputAssetsUscTotalKey = "assets_usc_total";
const outputAssetsImmigrantTotalKey = "assets_immigrant_total";
const output864aAssetsTotalKey = "assets_864a_total"; // TODO avoid json properties which start with numbers
const outputAllPrimaryAssetsKey = "all_primary_assets";
const outputJsTotalPrimaryIncomeKey = "js_total_primary_income";
const outputJsAssetsUscTotalKey = "js_assets_usc_total";
const outputJs864aAssetsTotalKey = "js_864a_assets_total";
const outputJsPrimaryAssetsKey = "js_primary_assets";
const outputJsPrimaryIncome = "js_primary_income";
const outputUsciAssetTotal = "usci_asset_total";

const expectedInputKeys = [
  sponsorIncome814Key /* FINF814 */,
  sponsorAsset815Key /* FINF815 */,
  sponsorAsset816Key /* FINF816 */,
  sponsorAsset817Key /* FINF817 */,
  immigrantIncome825Key /* FINF825 */,
  immigrantAsset826Key /* FINF826 */,
  immigrantAsset827Key /* FINF827 */,
  immigrantAsset828Key /* FINF828 */,
  finf811Key /* FINF811 */,
  finf812Key /* FINF812 */,
  it381Key /* IT381 */,
  it382Key /* IT382 */,
  it382aKey /* IT382a */,
  stateKey /* PI351 */,
  dependentChildrenApplicantKey /* FINF811a */,
  otherDependentApplicantKey /* FINF812b */,
  otherHouseholdMemberApplicantKey /* FINF812e */,
];

interface CalculatorInput {
  usPriorSponsored: number;
  usChildInHousehold: number;
  usOtherDependent: number;
  usCitizenIncome: number;
  usCitizenAssetsTotal: number;
  immigrantIncome: number;
  immigrantAssetsTotal: number;
  member864aIncome: number;
  member864aAssetsTotal: number;
  member864aIncrementCountFlag: string;
  jsIncome: number;
  jsAssetsTotal: number;
  js864aIncome: number;
  js864aAssetsTotal: number;
  js864aIncrementCountFlag: string;
  usCitizenState: string;
  jsState: string;
  householdSizeAdditionalAdjustment: number;
  householdSizeAdditionalAdjustmentJS864a: number;
  jsMarriage: number;
  jsPriorSponsored: number;
  jsChildInHouse: number;
  jsOtherDependent: number;
  presenceOfMemberFlag: string;
  presenceOfEligibleSponsorFlag: string;
  presenceOfMemberJS864aFlag: string;
}

interface HouseholdTotals {
  householdSize: number;
  adjustedHouseholdSize: number;
  jsHouseholdSize: number;
  adjustedJsHouseholdSize: number;
}

function calculateIncomeFlag(
  incomeLimits: IncomeLimitItem[],
  incomeValues: IncomeValues
): OutputValues {
  logFunction(`Received input - ${JSON.stringify(incomeValues)}`);
  // Verify that each expected key exists in incomeValues
  const allKeysExist = expectedInputKeys.every((key) => key in incomeValues);

  if (!allKeysExist) {
    return {};
    // throw new Error("Not all expectedKeys exist in incomeValues");
  }

  const sponsorIncome: number = getNumber(sponsorIncome814Key, incomeValues);
  const immigrantIncome: number = getNumber(
    immigrantIncome825Key,
    incomeValues
  );
  const totalIncome: number = sponsorIncome + immigrantIncome;

  const sponsorAsset1: number = getNumber(sponsorAsset815Key, incomeValues);
  const sponsorAsset2: number = getNumber(sponsorAsset816Key, incomeValues);
  const sponsorAsset3: number = getNumber(sponsorAsset817Key, incomeValues);
  const sponsorTotalAsset: number =
    sponsorAsset1 + sponsorAsset2 + sponsorAsset3;

  const immigrantAsset1: number = getNumber(immigrantAsset826Key, incomeValues);
  const immigrantAsset2: number = getNumber(immigrantAsset827Key, incomeValues);
  const immigrantAsset3: number = getNumber(immigrantAsset828Key, incomeValues);
  const immigrantTotalAsset: number =
    immigrantAsset1 + immigrantAsset2 + immigrantAsset3;

  const totalAsset: number = sponsorTotalAsset + immigrantTotalAsset;

  const it382: number = getIT382Value(incomeValues);
  const finf811: number = getNumber(finf811Key, incomeValues);
  const finf812: number = getNumber(finf812Key, incomeValues);

  const householdSize: number = 2 + finf811 + finf812 + it382;

  const state: string = getStateValue(incomeValues);

  const matches: IncomeLimitItem[] = incomeLimits.filter(
    (item) => item.state === state && item.householdSize === householdSize
  );
  const incomeLimit: number = matches.length > 0 ? matches[0].income : 0;
  const shortfall = incomeLimit - totalIncome;

  var flag = 0;
  if (sponsorIncome >= incomeLimit) {
    flag = 1;
  } else if (totalIncome >= incomeLimit) {
    flag = 2;
  } else if (sponsorTotalAsset >= shortfall * 3) {
    flag = 3;
  } else if (totalAsset >= shortfall * 3) {
    flag = 4;
  }

  const dependentChildrenApplicant = getNumber(
    dependentChildrenApplicantKey,
    incomeValues
  );
  const otherDependentApplicant = getNumber(
    otherDependentApplicantKey,
    incomeValues
  );
  const otherHouseholdMemberApplicant = getNumber(
    otherHouseholdMemberApplicantKey,
    incomeValues
  );
  const householdSizeTotalApplicant =
    2 +
    dependentChildrenApplicant +
    otherDependentApplicant +
    otherHouseholdMemberApplicant;

  const outputValues: OutputValues = {};
  outputValues[incomeLimitFlagKey] = flag.toString();
  outputValues[totalIncomeKey] = totalIncome.toString();
  outputValues[sponsorTotalAssetKey] = sponsorTotalAsset.toString();
  outputValues[immigrantTotalAssetKey] = immigrantTotalAsset.toString();
  outputValues[totalAssetKey] = totalAsset.toString();
  outputValues[householdSizeKey] = householdSize.toString();
  outputValues[householdSizeTotalApplicantKey] =
    householdSizeTotalApplicant.toString();

    logFunction(`Output - ${JSON.stringify(outputValues)}`);
  return outputValues;
}

function calculateIncomeFlagV2(
  incomeLimits: IncomeLimitItem[],
  incomeValues: IncomeValues
): OutputValues {
  logFunction(`Received input - ${JSON.stringify(incomeValues)}`);

  const calcInput = getCalculatorInput(incomeValues);
  const householdTotals = getHouseholdTotals(calcInput);
  let incomeLimitFlag = 0;

  const usCitizenIncomeLimit = getIncomeLimit(incomeLimits, calcInput.usCitizenState, householdTotals.adjustedHouseholdSize);
  const totalPrimaryIncome = calcInput.usCitizenIncome + calcInput.immigrantIncome;
  const usCitizenShortfall = usCitizenIncomeLimit - totalPrimaryIncome;

  const uscAssetShortfall = calcInput.usCitizenAssetsTotal - (usCitizenShortfall * 3);
  const uscImgAssetShortFall = (calcInput.usCitizenAssetsTotal + calcInput.immigrantAssetsTotal) - (usCitizenShortfall * 3);

  // Step2: 
  // check to see if us citizen income sufficient to meet financial bar- apply the adjustment is household size is >8 per existing code. us citizen state should be used for calculation
  // If condition successful set income_limit_flag == 1
  if (calcInput.usCitizenIncome >= usCitizenIncomeLimit) {
    logFunction("calcInput.usCitizenIncome - " + calcInput.usCitizenIncome);
    logFunction("usCitizenIncomeLimit - " + usCitizenIncomeLimit);
    incomeLimitFlag = 1;
  }
  // else
  // Step 3: 
  // check to see if us citizen income + immigrant income sufficient to meet financial bar- apply the adjustment is household size is >8 per existing code. us citizen state should be used for calculation
  // If condition successful set income_limit_flag == 2
  else if (totalPrimaryIncome >= usCitizenIncomeLimit) {
    logFunction("totalPrimaryIncome - " + totalPrimaryIncome);
    logFunction("usCitizenIncomeLimit - " + usCitizenIncomeLimit);
    incomeLimitFlag = 2;
  }
  // else
  // Step 4:
  // check to see if us citizen income + immigrant income + us citizen assets sufficient to meet financial bar- apply the adjustment is household size is >8 per existing code. us citizen state should be used for calculation
  // If condition successful set income_limit_flag == 3
  else if (uscAssetShortfall >= 0) {
    logFunction("uscAssetShortfall - " + uscAssetShortfall);
    incomeLimitFlag = 3;
  }
  // else
  // Step 5:
  // check to see if us citizen income + immigrant income + us citizen assets + immigrant assets  sufficient to meet financial bar- apply the adjustment is household size is >8 per existing code. us citizen state should be used for calculation
  // If condition successful set income_limit_flag == 4
  else if (uscImgAssetShortFall >= 0) {
    logFunction("uscImgAssetShortFall - " + uscImgAssetShortFall);
    incomeLimitFlag = 4;
  }
  // else
  // Step 6:
  // check if Flag to establish presence of 864a member == Y
  // if flag is Y proceed to step 7, else skip to step 10
  else if (calcInput.presenceOfMemberFlag === "Y") {
    // Steps 7 - 17:
    incomeLimitFlag = getIncomeLimitFlagForPresenceOfMember(
      incomeLimits,
      calcInput,
      householdTotals,
      uscImgAssetShortFall,
      incomeLimitFlag);
  }
  // Step 10:
  // check to see if flag to establish presence for an eligible joint sponsor: JSeligflag == Y
  // if Y proceed to step 11, else skip to step 18
  else if (calcInput.presenceOfEligibleSponsorFlag === "Y") {
    // Steps 11 - 17:
    incomeLimitFlag = getIncomeLimitFlagForPresenceOfEligibleSponsor(
      incomeLimits,
      calcInput,
      householdTotals,
      incomeLimitFlag);
  }
  // Step 18:
  // If none of the above has been met,set the income_limit_flag == 0
  else {
    incomeLimitFlag = 0;
  }

  // Outputs expected:
  // Income_limit_flag
  // initial_household_size
  // adjusted_household_size
  // JS_ intial_household_size (set to 0 if not computed)
  // JS_ adjusted_household_size (set to 0 if not computed)

  // total_primary_income
  // total_house_income (total_primary_income + 864a income)
  // Assets_USC_total (us citizen assets total)
  // Assets_immigrant_total (immigrant asset total)
  // 864a_assets_total (864a assets total) 
  // all_primary_assets (Assets_USC_total + Assets_immigrant_total + 864a_assets_total)


  // JS_total_primary_income (JSincome + JS864a income)
  // JS_Assets_USC_total (joint sponsor assets total)
  // JS864a_assets_total (JS864a assets total) 
  // JS_primary_assets (JS_Assets_USC_total + JS864a_assets_total)

  const outputValues: OutputValues = {};
  outputValues[outputIncomeLimitFlagKey] = incomeLimitFlag.toString();
  outputValues[outputInitialHouseholdSizeKey] = householdTotals.householdSize.toString();
  outputValues[outputAdjustedHouseholdSizeKey] = householdTotals.adjustedHouseholdSize.toString();
  outputValues[outputJsInitialHouseholdSizeKey] = householdTotals.jsHouseholdSize.toString();
  outputValues[outputJsAdjustedHouseholdSizeKey] = householdTotals.adjustedJsHouseholdSize.toString();
  outputValues[outputTotalPrimaryIncomeKey] = totalPrimaryIncome.toString();
  outputValues[outputTotalHouseIncomeKey] = (totalPrimaryIncome + calcInput.member864aIncome).toString();
  outputValues[outputAssetsUscTotalKey] = calcInput.usCitizenAssetsTotal.toString();
  outputValues[outputAssetsImmigrantTotalKey] = calcInput.immigrantAssetsTotal.toString();
  outputValues[output864aAssetsTotalKey] = calcInput.member864aAssetsTotal.toString();
  outputValues[outputAllPrimaryAssetsKey] = (calcInput.usCitizenAssetsTotal + calcInput.immigrantAssetsTotal + calcInput.member864aAssetsTotal).toString();
  outputValues[outputJsTotalPrimaryIncomeKey] = (calcInput.jsIncome + calcInput.js864aIncome).toString();
  outputValues[outputJsAssetsUscTotalKey] = calcInput.jsAssetsTotal.toString();
  outputValues[outputJs864aAssetsTotalKey] = calcInput.js864aAssetsTotal.toString();
  outputValues[outputJsPrimaryAssetsKey] = (calcInput.jsAssetsTotal + calcInput.js864aAssetsTotal).toString();
  outputValues[outputJsPrimaryIncome] = calcInput.jsIncome.toString();
  outputValues[outputUsciAssetTotal] = (calcInput.usCitizenAssetsTotal + calcInput.immigrantAssetsTotal).toString();

  const dependentChildrenApplicant = getNumber(dependentChildrenApplicantKey, incomeValues);
  const otherDependentApplicant = getNumber(otherDependentApplicantKey, incomeValues);
  const otherHouseholdMemberApplicant = getNumber(otherHouseholdMemberApplicantKey, incomeValues);
  
  const householdSizeTotalApplicant =
    2 +
    dependentChildrenApplicant +
    otherDependentApplicant +
    otherHouseholdMemberApplicant;
  
  outputValues[householdSizeTotalApplicantKey] = householdSizeTotalApplicant.toString();

  logFunction(`Output - ${JSON.stringify(outputValues)}`);
  return outputValues;
}

function getIncomeLimitFlagForPresenceOfMember(
  incomeLimits: IncomeLimitItem[],
  calcInput: CalculatorInput,
  householdTotals: HouseholdTotals,
  uscImgAssetShortFall: number,
  originalIncomeLimitFlag: number
) {
  // Math.abs is needed to ensure the shortfall is positive
  // at this point member864a should be able to meet the shortfall.
  const expectedShortfall = Math.abs(uscImgAssetShortFall);
  const uscImgIncomeShortFall = expectedShortfall / 3;
  const member864aAssetShortfall = calcInput.member864aIncome - uscImgIncomeShortFall;

  // Step 7: 
  // adjust household size to initial household size + I864aHSflag ( name it adjusted house hold size)

  // Step 8:
  // check to see if us citizen income + immigrant income + us citizen assets + immigrant assets +864a income sufficient to meet financial bar- apply the adjustment is household size is >8 per existing code. us citizen state should be used for calculation
  // If condition successful set income_limit_flag == 5
  if (calcInput.member864aIncome >= uscImgIncomeShortFall) {
    logFunction("calcInput.member864aIncome - " + calcInput.member864aIncome);
    logFunction("uscImgIncomeShortFall - " + uscImgIncomeShortFall);
    return 5;
  }
  // Step 9:
  // check to see if us citizen income + immigrant income + us citizen assets + immigrant assets +864a income + 864a assets sufficient to meet financial bar- apply the adjustment is household size is >8 per existing code. us citizen state should be used for calculation
  // If condition successful set income_limit_flag == 6
  else if (calcInput.member864aAssetsTotal >= Math.abs(member864aAssetShortfall * 3)) {
    logFunction("calcInput.member864aAssetsTotal - " + calcInput.member864aAssetsTotal);
    logFunction("member864aAssetShortfall - " + member864aAssetShortfall);
    return 6;
  }
  // Step 10:
  // check to see if flag to establish presence for an eligible joint sponsor: JSeligflag == Y
  // if Y proceed to step 11, else skip to step 18
  else if (calcInput.presenceOfEligibleSponsorFlag === "Y") {
    // Steps 11 - 17:
    return getIncomeLimitFlagForPresenceOfEligibleSponsor(
      incomeLimits,
      calcInput,
      householdTotals,
      originalIncomeLimitFlag);
  }

  return originalIncomeLimitFlag;
}

function getIncomeLimitFlagForPresenceOfEligibleSponsor(
  incomeLimits: IncomeLimitItem[],
  calcInput: CalculatorInput,
  householdTotals: HouseholdTotals,
  originalIncomeLimitFlag: number
) {
  // Step 11:
  // calculate JS household size
  // JS initial household size == 1 + sum joint sponsor marriage: Q4JSI864a
  // joint sponsor prior sponsored: Q5JSI864
  // joint sponsor child in house: Q6JSI864
  // joint sponsor other dependent: Q7JSI864
  const jsIncomeLimit = getIncomeLimit(incomeLimits, calcInput.jsState, householdTotals.adjustedJsHouseholdSize);
  const jsIncomeShortfall = jsIncomeLimit - calcInput.jsIncome;
  const jsAssetShortfall = jsIncomeShortfall * 3;

  // Step 12:
  // check to see if JS income sufficient to meet financial bar- apply the adjustment is household size is >8 per existing code. joint sponsor state should be used for calculation
  // If condition successful set income_limit_flag == 7
  if (calcInput.jsIncome >= jsIncomeLimit) {
    logFunction("calcInput.jsIncome - " + calcInput.jsIncome);
    logFunction("jsIncomeLimit - " + jsIncomeLimit);
    return 7;
  }
  // else
  // Step 13:
  // check to see if JS income + JS assets sufficient to meet financial bar- apply the adjustment is household size is >8 per existing code. us citizen state should be used for calculation
  // If condition successful set income_limit_flag == 8
  else if (calcInput.jsAssetsTotal >= jsAssetShortfall) {
    logFunction("calcInput.jsAssetsTotal - " + calcInput.jsAssetsTotal);
    logFunction("jsAssetShortfall - " + jsAssetShortfall);
    return 8;
  }
  // else
  // Step 14:
  // check if Flag to establish presence of Joint sponsor 864a member == Y
  // if flag is Y proceed to step 15, else skip to step 18
  else if (calcInput.presenceOfMemberJS864aFlag === "Y") {
    // Steps 15 - 17:
    return getIncomeLimitFlagForPresenceOfMemberJS864a(
      calcInput,
      jsAssetShortfall,
      originalIncomeLimitFlag);
  }

  return originalIncomeLimitFlag;
}

function getIncomeLimitFlagForPresenceOfMemberJS864a(
  calcInput: CalculatorInput,
  jsAssetShortfall: number,
  originalIncomeLimitFlag: number
) {
  // Math.abs is needed to ensure the shortfall is positive
  // at this point member864a should be able to meet the shortfall.
  const remainingJsAssetsShortfall = Math.abs(calcInput.jsAssetsTotal - jsAssetShortfall);
  const js864aIncomeShortfall = remainingJsAssetsShortfall / 3;
  const js864aAssetShortfall = Math.abs(calcInput.js864aIncome - js864aIncomeShortfall);

  // Step 15:
  // Adjust Joint sponsor household size: JS initial household size + I864aHSflag ( name it adjusted JS house hold size)

  // check to see if JS income + JS assets + JS864a income sufficient to meet financial bar- apply the adjustment is household size is >8 per existing code. us citizen state should be used for calculation
  // If condition successful set income_limit_flag == 9               
  if (calcInput.js864aIncome >= js864aIncomeShortfall) {
    logFunction("calcInput.js864aIncome - " + calcInput.js864aIncome);
    logFunction("js864aIncomeShortfall - " + js864aIncomeShortfall);
    return 9;
  }
  // else
  // Step 17:
  // check to see if JS income + JS assets + JS864a income + JS864a assets suffecient to meet financial bar- apply the adjustment is household size is >8 per existing code. us citizen state should be used for calculation
  // If condition successful set income_limit_flag == 10
  else if (calcInput.js864aAssetsTotal >= js864aAssetShortfall * 3) {
    logFunction("calcInput.js864aAssetsTotal - " + calcInput.js864aAssetsTotal);
    logFunction("js864aAssetShortfall - " + js864aAssetShortfall);
    return 10;
  }

  return originalIncomeLimitFlag;
}

function getHouseholdTotals(calcInput: CalculatorInput): HouseholdTotals {
  let householdSize = calcInput.usPriorSponsored + calcInput.usChildInHousehold + calcInput.usOtherDependent + 2;
  let adjustedHouseholdSize = householdSize;
  let jsHouseholdSize = 0;
  let adjustedJsHouseholdSize = 0;

  // NOTE: One thing we learned is that when the UI updates textboxes with default values based on other selections, the calculator is not invoked.

  if (calcInput.presenceOfMemberFlag === "Y" && calcInput.member864aIncrementCountFlag === "N") {
    adjustedHouseholdSize = householdSize + 1;
  }

  if (calcInput.presenceOfEligibleSponsorFlag === "Y") {
    jsHouseholdSize = 2 + calcInput.jsMarriage + calcInput.jsPriorSponsored + calcInput.jsChildInHouse + calcInput.jsOtherDependent;
    adjustedJsHouseholdSize = jsHouseholdSize;
    if (calcInput.presenceOfMemberJS864aFlag === "Y" && calcInput.js864aIncrementCountFlag === "N") {
      adjustedJsHouseholdSize = jsHouseholdSize + 1;
    }
  }

  return {
    householdSize,
    adjustedHouseholdSize,
    jsHouseholdSize,
    adjustedJsHouseholdSize,
  };
}

function getIncomeLimit(incomeLimits: IncomeLimitItem[], state: string, householdSize: number): number {
  const matches: IncomeLimitItem[] = incomeLimits.filter(
    (item) => item.state === state && item.householdSize === householdSize
  );
  return matches.length > 0 ? matches[0].income : 0;
}

function getCalculatorInput(incomeValues: IncomeValues): CalculatorInput {
  return {
    usPriorSponsored: getNumber(usCitizenPriorSponsoredKey, incomeValues),
    usChildInHousehold: getNumber(usCitizenChildInHouseholdKey, incomeValues),
    usOtherDependent: getNumber(usCitizenOtherDependentKey, incomeValues),
    usCitizenIncome: getNumber(usCitizenIncomeKey, incomeValues),
    usCitizenAssetsTotal: getNumber(usCitizenAssetBankKey, incomeValues) + getNumber(usCitizenAssetRealEstateKey, incomeValues) + getNumber(usCitizenAssetStockKey, incomeValues),
    immigrantIncome: getNumber(immigrantIncomeKey, incomeValues),
    immigrantAssetsTotal: getNumber(immigrantAssetBankKey, incomeValues) + getNumber(immigrantAssetRealEstateKey, incomeValues) + getNumber(immigrantAssetStockKey, incomeValues),
    member864aIncome: getNumber(member864aIncomeKey, incomeValues),
    member864aAssetsTotal: getNumber(member864aAssetBankKey, incomeValues) + getNumber(member864aAssetRealEstateKey, incomeValues) + getNumber(member864aAssetStockKey, incomeValues),
    member864aIncrementCountFlag: getFlagValueForKey(member864aIncrementCountFlagKey, incomeValues),
    jsIncome: getNumber(jsIncomeKey, incomeValues),
    jsAssetsTotal: getNumber(jsAssetBankKey, incomeValues) + getNumber(jsAssetRealEstateKey, incomeValues) + getNumber(jsAssetStockKey, incomeValues),
    js864aIncome: getNumber(js864aIncomeKey, incomeValues),
    js864aAssetsTotal: getNumber(js864aAssetBankKey, incomeValues) + getNumber(js864aAssetRealEstateKey, incomeValues) + getNumber(js864aAssetStockKey, incomeValues),
    js864aIncrementCountFlag: getFlagValueForKey(js864aIncrementCountFlagKey, incomeValues),
    usCitizenState: getStateValueFromKey(usCitizenStateKey, incomeValues),
    jsState: getStateValueFromKey(jsStateKey, incomeValues),
    householdSizeAdditionalAdjustment: getNumber(householdSizeAdjustmentNeededFlagKey, incomeValues),
    householdSizeAdditionalAdjustmentJS864a: getNumber(householdSizeAdjustmentJS864aFlagKey, incomeValues),
    jsMarriage: getNumber(jsMarriageKey, incomeValues),
    jsPriorSponsored: getNumber(jsPriorSponsoredKey, incomeValues),
    jsChildInHouse: getNumber(jsChildInHouseKey, incomeValues),
    jsOtherDependent: getNumber(jsOtherDependentKey, incomeValues),
    presenceOfMemberFlag: getFlagValueForKey(presenceOfMemberFlagKey, incomeValues),
    presenceOfEligibleSponsorFlag: getFlagValueForKey(presenceOfEligibleSponsorFlagKey, incomeValues),
    presenceOfMemberJS864aFlag: getFlagValueForKey(presenceOfMemberJS864aFlagKey, incomeValues),
  }
}

function getNumber(key: string, incomeValues: IncomeValues) {
  let value: number = incomeValues[key] // FINF815 + FINF816 + FINF817
    ? +incomeValues[key]
    : 0;

  if (isNaN(value) || value < 0) {
    error(
      `${key} must be greater than or equal to 0. Received - '${incomeValues[key]}'`
    );
    value = 0;
  }

  return value;
}

function getIT382Value(incomeValues: IncomeValues) {
  const value =
    incomeValues[it381Key] === "Y" &&
      incomeValues[it382aKey] === "Y" &&
      incomeValues[it382Key]
      ? getNumber(it382Key, incomeValues)
      : 0;

  return value;
}

function getStateValue(incomeValues: IncomeValues) {
  let state: string = incomeValues[stateKey] ? incomeValues[stateKey] : "";

  if (state !== "AK" && state !== "HI") {
    state = "Contiguous";
  }

  return state;
}

function getStateValueFromKey(key: string, incomeValues: IncomeValues) {
  let state: string = incomeValues[key] ? incomeValues[key] : "";

  if (state !== "AK" && state !== "HI") {
    state = "Contiguous";
  }

  return state;
}



function error(message: string) {
  logErrorFunction("calculateIncomeFlag - " + message);
}

function getAdditionalPersonIncomeForState(state: string) {
  const additionalPersonIncome: PersonIncome = {
    Contiguous: 6425,
    AK: 6425,
    HI: 7387,
  };

  return additionalPersonIncome[state];
}

function getFlagValueForKey(key: string, incomeValues: IncomeValues, defaultValue: string = "N"): string {
  return incomeValues[key] === "Y" ? "Y" : defaultValue;
}

// You might still want to export the function in case you'll use it elsewhere.
export { calculateIncomeFlag, calculateIncomeFlagV2 };
