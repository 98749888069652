// ChatComponent.tsx
import React from 'react';
import type { StreamChat } from 'stream-chat';
import { useCreateChatClient, Chat, Channel, ChannelHeader, MessageInput, MessageList, Thread, Window } from 'stream-chat-react';

import 'stream-chat-react/dist/css/v2/index.css';

interface ChatComponentProps {
  apiKey: string;
  userId: string;
  channelId: string;
  userToken: string;
  userData: { id: string };
  bootstrapData?: any;
}

const ChatDialogComponent: React.FC<ChatComponentProps> = ({ apiKey, channelId, userToken, userData, bootstrapData }) => {
  let chatClient: StreamChat | null = null;

  chatClient = useCreateChatClient({
    apiKey: apiKey,
    tokenOrProvider: userToken,
    userData: userData,
  });

  // chatClient = useCreateChatClient({
  //   apiKey: "awg3h7k3fdgv",
  //   tokenOrProvider: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGF3eWVyMSJ9.oNXyZlu-RK1KdyoqgOiNUYN20uO6u9-1EsiKMP45lvs',
  //   userData: { id: 'lawyer1' },
  // });

  // chatClient = useCreateChatClient({
  //   apiKey: "hc3gs9frfgz3",
  //   tokenOrProvider: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiY2xpZW50XzQifQ.78dJs23oINWJAUp7oeNA3KF_aZMjbjBqIrnMIQLmNIc',
  //   userData: { id: bootstrapData?.createdBy?.toString() },
  // });

  if (!chatClient) return <div>Loading chat client...</div>;

  const channel = chatClient.channel('messaging', channelId);

  return (
    <Chat client={chatClient}>
      {channel && (
        <Channel channel={channel}>
          <Window>
            <ChannelHeader />
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      )}
    </Chat>
  );
};

export default ChatDialogComponent;