// import React, { useEffect, useRef, useState } from "react";
// import Sidebar from "./sidebar";
// import { Box } from "@mui/material"; // Import Material-UI components
// // import "./assets/pspdfkit";

// interface Annotation {
//   id?: number;
//   type: string;
//   x: number;
//   y: number;
//   width: number;
//   height: number;
//   value?: string;
//   pageIndex?: number;
// }

// interface PdfViewerComponentProps {}

// const PdfViewerComponent: React.FC<PdfViewerComponentProps> = (props) => {
//   const docPath = require("./i-864.pdf");

//   const containerRef = useRef<HTMLDivElement>(null);
//   const [pspdfkitInstance, setPspdfkitInstance] = useState<any>(null); // Adjust type as per your PSPDFKit instance type
//   const [annotationsList, setAnnotationsList] = useState<Annotation[]>([]);
//   const [annotationType, setAnnotationType] = useState<string>("input");
//   const annotationTypeRef = useRef<string>(annotationType); // Create a ref for annotationType
//   const [pdfFile, setPdfFile] = useState<File | string>(docPath);

//   const toBase64 = (file: File) =>
//     new Promise<string>((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => resolve(reader.result as string);
//       reader.onerror = (error) => reject(error);
//     });

//   useEffect(() => {
//     const savedAnnotations = localStorage.getItem("pdfViewerAnnotations");
//     if (savedAnnotations) {
//       setAnnotationsList(JSON.parse(savedAnnotations));
//     }
//   }, []);

//   useEffect(() => {
//     const container = containerRef.current;

//     const loadPSPDFKitInstance = async () => {
//       if (pspdfkitInstance) return; // Prevent reloading if instance already exists

//       const PSPDFKit: any = await import("pspdfkit");
//       let instance: any;

//       const savedInstanceData = localStorage.getItem("savedInstance");

//       if (savedInstanceData) {
//         const parsedData = JSON.parse(savedInstanceData);
//         instance = await PSPDFKit?.load({
//           container,
//           document: parsedData.document,
//           baseUrl: parsedData.baseUrl,
//           toolbarItems: [
//             ...PSPDFKit.defaultToolbarItems,
//             {
//               type: "form-creator"
//             }
//           ], 
//           // Add other necessary properties
//         });
//       } else {
//         instance = await PSPDFKit.load({
//           disableWebAssemblyStreaming: true,
//           container,
//           document: pdfFile,
//           toolbarItems: [
//             ...PSPDFKit.defaultToolbarItems,
//             {
//               type: "form-creator"
//             }
//           ],        
//           baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
//         });

//         const serializedData = {
//           document: pdfFile,
//           baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
//           // Add other necessary properties
//         };

//         localStorage.setItem("savedInstance", JSON.stringify(serializedData));
//       }

//       const savedAnnotations = localStorage.getItem("pdfViewerAnnotations");
//       if (savedAnnotations) {
//         const annotations = JSON.parse(savedAnnotations);
//         annotations.forEach((annotation: Annotation) => {
//           const rectAnnotation = new PSPDFKit.Annotations.RectangleAnnotation({
//             boundingBox: new PSPDFKit.Geometry.Rect({
//               left: annotation.x,
//               top: annotation.y,
//               width: annotation.width,
//               height: annotation.height,
//             }),
//             pageIndex: annotation?.pageIndex,
//           });
//           instance.create(rectAnnotation);
//         });
//       }

//       setPspdfkitInstance(instance);

//       instance.addEventListener("annotations.create", async (event: any) => {
//         const newAnnotations = event;
//         const newBoxesCoordinates = newAnnotations
//           ?.filter(
//             (annotation: any) =>
//               annotation instanceof PSPDFKit.Annotations.RectangleAnnotation
//           )
//           .map((annotation: any) => {
//             const { left: x, top: y, width, height } = annotation.boundingBox;
//             const id = parseInt(`${Date.now()}${Math.random()}`, 10).toString();

//             return {
//               x,
//               y,
//               width,
//               height,
//               pageIndex: annotation.pageIndex,
//               type: annotationTypeRef?.current, // Use the ref value here
//               length: 123,
//               id,
//             };
//           });

//         const existingAnnotationsString = localStorage.getItem(
//           "pdfViewerAnnotations"
//         );
//         const existingAnnotations: Annotation[] = existingAnnotationsString
//           ? JSON.parse(existingAnnotationsString)
//           : [];
//         const updatedAnnotationsList = mergeAnnotations(
//           existingAnnotations,
//           newBoxesCoordinates
//         );

//         localStorage.setItem(
//           "pdfViewerAnnotations",
//           JSON.stringify(updatedAnnotationsList)
//         );
//         setAnnotationsList(updatedAnnotationsList);
//       });

//       const items = instance.toolbarItems;
//       const filteredItems = items?.filter(
//         (item: any) =>
//           ![
//             "ink",
//             "text",
//             "callout",
//             "text-highlighter",
//             "ink-eraser",
//             "cloudy-rectangle",
//             "line",
//             "arrow",
//             "dashed-rectangle",
//             "ellipse",
//             "cloudy-ellipse",
//             "dashed-ellipse",
//             "polygon",
//             "cloudy-polygon",
//             "dashed-polygon",
//             "polyline",
//             "signature",
//             "image",
//             "stamp",
//             "note",
//             "link",
//             "highlighter",
//             "pan",
//             "zoom-out",
//             "zoom-in",
//             "zoom-mode",
//             "sidebar-thumbnails",
//             "sidebar-document-outline",
//             "sidebar-annotations",
//             "sidebar-bookmarks",
//             "sidebar-signatures",
//             "sidebar-layers",
//             "pager",
//             "spacer",
//             "annotate",
//             "print",
//             "document-editor",
//             "document-crop",
//             "search",
//             "export-pdf",
//             "debug",
//             "content-editor",
//             "link",
//             "multi-annotations-selection",
//             "callout",
//           ].includes(item.type)
//       );
//       instance.setToolbarItems(filteredItems);
//     };

//     if (!pspdfkitInstance) {
//       loadPSPDFKitInstance();
//     }

//     return () => pspdfkitInstance && pspdfkitInstance?.unload(container);
//   }, [pdfFile]);

//   useEffect(() => {
//     // Update the ref value whenever annotationType changes
//     annotationTypeRef.current = annotationType;
//   }, [annotationType]);

//   const mergeAnnotations = (
//     existingAnnotations: Annotation[],
//     newAnnotations: Annotation[]
//   ) => {
//     const mergedAnnotations = [...existingAnnotations];
//     newAnnotations.forEach((newAnnotation) => {
//       const isDuplicate = existingAnnotations.some(
//         (existingAnnotation) =>
//           existingAnnotation.x === newAnnotation.x &&
//           existingAnnotation.y === newAnnotation.y &&
//           existingAnnotation.width === newAnnotation.width &&
//           existingAnnotation.height === newAnnotation.height &&
//           existingAnnotation.pageIndex === newAnnotation.pageIndex
//       );
//       if (!isDuplicate) {
//         mergedAnnotations.push(newAnnotation);
//       }
//     });
//     return mergedAnnotations;
//   };

//   const handleInputChange = (
//     index: number,
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     const newAnnotations = [...annotationsList];
//     newAnnotations[index].value = event.target.value;
//     localStorage.setItem(
//       "pdfViewerAnnotations",
//       JSON.stringify(newAnnotations)
//     );
//     setAnnotationsList(newAnnotations);
//   };

//   const onFileChange = async (event: any) => {
//     const file = event.target.files[0];

//     if (file) {
//       const base64File = await toBase64(file);
//       setPdfFile(base64File);
//       localStorage.removeItem("pdfViewerAnnotations");
//       setAnnotationsList([]); // Clear annotations when a new PDF is uploaded
//     }
//   };

//   return (
//     <Box sx={{ display: "flex" }}>
//       <Sidebar
//         annotationType={annotationType}
//         setAnnotationType={setAnnotationType}
//         annotations={annotationsList}
//         handleInputChange={handleInputChange}
//         onFileChange={onFileChange}
//         pdfFile={pdfFile}
//         count={annotationsList?.length} // Adjust as per your requirement
//       />

//       <div
//         ref={containerRef}
//         style={{ width: "100%", height: "calc(100vh - 133px)" }}
//       />
//     </Box>
//   );
// };

// export default PdfViewerComponent;



import React, { useEffect, useRef, useState } from "react";
import Sidebar from "./sidebar";
import { Box } from "@mui/material"; // Import Material-UI components

interface Annotation {
  id?: number;
  type: string;
  x: number;
  y: number;
  width: number;
  height: number;
  value?: string;
  pageIndex?: number;
}

interface PdfViewerComponentProps {}

const PdfViewerComponent: React.FC<PdfViewerComponentProps> = (props) => {
  const docPath = require("./i-864.pdf");

  const containerRef = useRef<HTMLDivElement>(null);
  const [pspdfkitInstance, setPspdfkitInstance] = useState<any>(null); // Adjust type as per your PSPDFKit instance type
  const [annotationsList, setAnnotationsList] = useState<Annotation[]>([]);
  const [annotationType, setAnnotationType] = useState<string>("input");
  const annotationTypeRef = useRef<string>(annotationType); // Create a ref for annotationType
  const [pdfFile, setPdfFile] = useState<File | string>(docPath);

  const toBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    const savedAnnotations = localStorage.getItem("pdfViewerAnnotations");
    if (savedAnnotations) {
      setAnnotationsList(JSON.parse(savedAnnotations));
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    const loadPSPDFKitInstance = async () => {
      if (pspdfkitInstance) return; // Prevent reloading if instance already exists

      const PSPDFKit: any = await import("pspdfkit");
      let instance: any;

      const savedInstanceData = localStorage.getItem("savedInstance");

      if (savedInstanceData) {
        const parsedData = JSON.parse(savedInstanceData);
        instance = await PSPDFKit?.load({
          container,
          document: parsedData.document,
          baseUrl: parsedData.baseUrl,
          toolbarItems: [
            ...PSPDFKit.defaultToolbarItems,
            {
              type: "form-creator"
            }
          ], 
          // Add other necessary properties
        });
      } else {
        instance = await PSPDFKit.load({
          disableWebAssemblyStreaming: true,
          container,
          document: pdfFile,
          toolbarItems: [
            ...PSPDFKit.defaultToolbarItems,
            {
              type: "form-creator"
            }
          ],        
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        });

        const serializedData = {
          document: pdfFile,
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
          // Add other necessary properties
        };

        localStorage.setItem("savedInstance", JSON.stringify(serializedData));
      }

      const savedAnnotations = localStorage.getItem("pdfViewerAnnotations");
      if (savedAnnotations) {
        const annotations = JSON.parse(savedAnnotations);
        annotations.forEach((annotation: Annotation) => {
          const rectAnnotation = new PSPDFKit.Annotations.RectangleAnnotation({
            boundingBox: new PSPDFKit.Geometry.Rect({
              left: annotation.x,
              top: annotation.y,
              width: annotation.width,
              height: annotation.height,
            }),
            pageIndex: annotation?.pageIndex,
          });
          instance.create(rectAnnotation);
        });
      }

      setPspdfkitInstance(instance);

      instance.addEventListener("annotations.create", async (event: any) => {
        const newAnnotations = event;
        const newBoxesCoordinates = newAnnotations.map((annotation: any) => {
          const { left: x, top: y, width, height } = annotation.boundingBox;
          const id = parseInt(`${Date.now()}${Math.random()}`, 10).toString();

          return {
            x,
            y,
            width,
            height,
            pageIndex: annotation.pageIndex,
            type: annotationTypeRef?.current, // Use the ref value here
            value: annotation.contents || "", // Store contents if any
            id,
          };
        });

        const existingAnnotationsString = localStorage.getItem(
          "pdfViewerAnnotations"
        );
        const existingAnnotations: Annotation[] = existingAnnotationsString
          ? JSON.parse(existingAnnotationsString)
          : [];
        const updatedAnnotationsList = mergeAnnotations(
          existingAnnotations,
          newBoxesCoordinates
        );

        localStorage.setItem(
          "pdfViewerAnnotations",
          JSON.stringify(updatedAnnotationsList)
        );
        setAnnotationsList(updatedAnnotationsList);
      });

      // Removing the filter to include all annotation types
      // const items = instance.toolbarItems;
      // instance.setToolbarItems(items);


      const items = instance.toolbarItems;
      const filteredItems = items?.filter(
        (item: any) =>
          ![
            "ink",
            "text",
            "callout",
            "text-highlighter",
            "ink-eraser",
            "cloudy-rectangle",
            "line",
            "arrow",
            "dashed-rectangle",
            "ellipse",
            "cloudy-ellipse",
            "dashed-ellipse",
            "polygon",
            "cloudy-polygon",
            "dashed-polygon",
            "polyline",
            "signature",
            "image",
            "stamp",
            "note",
            "link",
            "highlighter",
            "pan",
            "zoom-out",
            "zoom-in",
            "zoom-mode",
            "sidebar-thumbnails",
            "sidebar-document-outline",
            "sidebar-annotations",
            "sidebar-bookmarks",
            "sidebar-signatures",
            "sidebar-layers",
            "pager",
            "spacer",
            "annotate",
            "print",
            "document-editor",
            "document-crop",
            "search",
            "export-pdf",
            "debug",
            "content-editor",
            "link",
            "multi-annotations-selection",
            "callout",
          ].includes(item.type)
      );
      instance.setToolbarItems(filteredItems);
    };

    if (!pspdfkitInstance) {
      loadPSPDFKitInstance();
    }

    return () => pspdfkitInstance && pspdfkitInstance?.unload(container);
  }, [pdfFile]);

  useEffect(() => {
    // Update the ref value whenever annotationType changes
    annotationTypeRef.current = annotationType;
  }, [annotationType]);

  const mergeAnnotations = (
    existingAnnotations: Annotation[],
    newAnnotations: Annotation[]
  ) => {
    const mergedAnnotations = [...existingAnnotations];
    newAnnotations.forEach((newAnnotation) => {
      const isDuplicate = existingAnnotations.some(
        (existingAnnotation) =>
          existingAnnotation.x === newAnnotation.x &&
          existingAnnotation.y === newAnnotation.y &&
          existingAnnotation.width === newAnnotation.width &&
          existingAnnotation.height === newAnnotation.height &&
          existingAnnotation.pageIndex === newAnnotation.pageIndex
      );
      if (!isDuplicate) {
        mergedAnnotations.push(newAnnotation);
      }
    });
    return mergedAnnotations;
  };

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newAnnotations = [...annotationsList];
    newAnnotations[index].value = event.target.value;
    localStorage.setItem(
      "pdfViewerAnnotations",
      JSON.stringify(newAnnotations)
    );
    setAnnotationsList(newAnnotations);
  };

  const onFileChange = async (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const base64File = await toBase64(file);
      setPdfFile(base64File);
      localStorage.removeItem("pdfViewerAnnotations");
      setAnnotationsList([]); // Clear annotations when a new PDF is uploaded
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar
        annotationType={annotationType}
        setAnnotationType={setAnnotationType}
        annotations={annotationsList}
        handleInputChange={handleInputChange}
        onFileChange={onFileChange}
        pdfFile={pdfFile}
        count={annotationsList?.length} // Adjust as per your requirement
      />

      <div
        ref={containerRef}
        style={{ width: "100%", height: "calc(100vh - 133px)" }}
      />
    </Box>
  );
};

export default PdfViewerComponent;
