import { useState } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  CircularProgress,
  Button,
  FormControl,
  Select,
  MenuItem,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { useAppDispatch } from "../../store/store";
import { addB2BEntity } from "../../store/thunk/billingThunk";

export function AddB2BEntityDialog({ open, handleClose }: any) {
  const dispatch = useAppDispatch();
  const [name, setName] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [error, setError] = useState<any>("");
  const [btnLoading, setBtnLoading] = useState<any>(false);

  const handleChangeField = (event: any) => {
    setName(event.target.value);
  };

  const handleUpdate = () => {
    if (!name) {
      setError("Name field is required.");
      return false;
    } else {
      let _request = { type: type, name: name };
      setBtnLoading(true);
      dispatch(addB2BEntity(_request)).then((res: any) => {
        setBtnLoading(false);
        handleClose();
      });
    }
  };
  const handleInputChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target;
    setType(value as string);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "14px" } }}
    >
      <DialogTitle>
        <span className="text-lg text-gray-900">Add New entity</span>
      </DialogTitle>
      <DialogContent>
        <div className="userViewDetail">
          <FormControl fullWidth>
            <label>Name</label>{" "}
            <TextField
              value={name}
              autoFocus
              margin="dense"
              id="name"
              type="email"
              fullWidth
              variant="standard"
              onChange={handleChangeField}
              error={error ? true : false}
              helperText={error ? error : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <label>Type</label>
            <Select
              labelId="demo-simple-type-label"
              id="demo-simple-type"
              value={type || ""}
              name="type"
              onChange={(event: any) => handleInputChange(event)}
            >
              <MenuItem value="UsaPath">UsaPath</MenuItem>
              <MenuItem value="LawyerApp">LawyerApp</MenuItem>
              <MenuItem value="ThirdParty">ThirdParty</MenuItem>
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"  variant="contained"
          onClick={() => handleUpdate()}
          disabled={!name || !type}
        >
          Add
        </Button>
        <Button variant="contained" color="inherit"   onClick={() => handleClose()} >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
