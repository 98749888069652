import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router";
import { anonymousUser } from "../../helper/service";
import LoaderComponent from "../loader";
import { useDispatch } from "react-redux";
import { getVerifyAuth } from "../../store/thunk/authThunk";


function PrivateRoute(props: any) {
  const { children } = props;
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  if (window.self !== window.top) {
    const urlParams = new URLSearchParams(window.location.search);

    const token = urlParams.get('token');

    if (token) {
      localStorage.setItem('iframeAccessToken', token);
    }
  }

  const accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;

  const userData: any = localStorage.getItem("userData")
    ? localStorage.getItem("userData")
    : null;

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);

  let localIsBootstrapCalled: any = localStorage.getItem("isBootstrapCalled");
  const isBootstrapCalled: any = JSON.parse(localIsBootstrapCalled);

  if (anonymousUser()) {
    return children;
  }

  if (!accessToken) {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const userdata = JSON.parse(userData);
  if (bootstrapData === null) {
    dispatch(getVerifyAuth(accessToken)).then((res: any) => {
      if (res?.payload?.status === 200) {
        if (!res?.payload?.data?.isVerified) {
          return <Navigate to="/verify" />;
        } else {
          let tempUserData = { ...userData };
          tempUserData["email_verified"] = true;
          localStorage.setItem("userData", JSON.stringify(tempUserData));
        }
      }
    });
  }
  else {
    if (!bootstrapData?.isVerified || !userdata?.email_verified) {
      return <Navigate to="/verify" />;
    }
  }

  return isBootstrapCalled ? accessToken && userData ? children : <Navigate to="/home" /> : <LoaderComponent />;
}

export default PrivateRoute;
