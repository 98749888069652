import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUiUser: [],
  uiUserDataShareScope: [],
  allUiUserDataShareScope: [],
  emulateUserList: [],
};

export const refreshReducer = createSlice({
  name: "formflow",
  initialState,
  reducers: {
    setAllUIUser: (state, action) => ({
      ...state,
      allUiUser: action.payload,
    }),
    setAllUiUserDataShareScope: (state, action) => ({
      ...state,
      allUiUserDataShareScope: action.payload,
    }),
    setUiUserDataShareScope: (state, action) => ({
      ...state,
      uiUserDataShareScope: action.payload,
    }),
    setEmulateUserList: (state, action) => ({
      ...state,
      emulateUserList: action.payload,
    }),
  },
});

export const {
  setAllUIUser,
  setAllUiUserDataShareScope,
  setUiUserDataShareScope,
  setEmulateUserList
} = refreshReducer.actions;
export default refreshReducer.reducer;
