import { useState } from "react";
import {
  TextField,
  Box,
  Select,
  FormControl,
  MenuItem,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
} from "@mui/material";

import "./index.css";

const FormVersion = (props: any) => {
  let { formState, formInputChange, formNameList, addNewForm, hideAddButton } =
    props;
  const [isDialog, setIsDialog] = useState(false);
  const [formName, setFormName] = useState("");

  const handleClose = () => {
    setIsDialog(false);
  };
  const handleOpen = () => {
    setIsDialog(true);
  };

  const handleFormName = (event: any) => {
    let formName = event.target.value;
    setFormName(formName);
  };

  const addName = () => {
    addNewForm(formName);
    handleClose();
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        marginBottom: 16,
      }}
    >
      <div style={{ display: "flex", alignItems: "center", marginRight: 0 }}>
        <label style={{ width: "100px" }}>Form Name</label>
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
          <Select
            labelId="demo-select-small-label1"
            id="demo-select-small1"
            value={formState?.flow_id || ""}
            label="Age"
            name="flow_id"
            onChange={formInputChange}
          >
            {formNameList &&
              formNameList.length > 0 &&
              formNameList.map((list: any, index: any) => {
                return (
                  <MenuItem key={index} value={list.id}>
                    {list.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>
      {!hideAddButton && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: 24,
            alignSelf: "center",
          }}
          className="form-add-btn"
        >
          <IconButton
            style={{
              background: "#ccc",
              height: "31px",
              width: "31px",
              paddingBottom: 13,
            }}
            aria-label="delete"
            onClick={handleOpen}
          >
            +
          </IconButton>
        </div>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <label style={{ width: "100px" }}>Form Version</label>
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small1"
            value={formState?.version || 1}
            label="Age"
            name="version"
            onChange={formInputChange}
          >
            {formNameList &&
              formNameList
                .filter((list: any) => list.id === formState.flow_id)
                .map((vList: any, index: any) => {
                  return (
                    vList &&
                    vList.version.map((listData: any) => {
                      return <MenuItem value={listData}>{listData}</MenuItem>;
                    })
                  );
                })}
          </Select>
        </FormControl>
      </div>

      <Dialog onClose={handleClose} open={isDialog}>
        <DialogTitle>Create Case Type</DialogTitle>
        <Box
          component="form"
          style={{ padding: "0 20px", width: "350px" }}
          sx={{
            "& > :not(style)": { m: 1, width: "300px" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            name="formName"
            onChange={handleFormName}
          />
          <Button variant="contained" onClick={addName}>
            Add
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default FormVersion;
