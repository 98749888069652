import React, { useEffect, useState } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import DatepickerIcons from "../../assets/images/datepickerIcons.png";

import {
  DatePicker,
  IDatePicker,
  defaultDatePickerStrings,
} from "@fluentui/react";

function DatePickerComponent(props: any) {
  const { handleFieldChange, editable, title, id, value, errors, isMaxDate } =
    props;

  const [isIphone, setIsIphone] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.includes("iPhone")) {
      setIsIphone(true);
    } else {
      setIsIphone(false);
    }
  }, []);

  const onChange = (id: any) => (date: any) => {
    if (date) {
      handleFieldChange(id, moment(date).format("MM/DD/YYYY"));
    }
  };

  const onChangeRaw = (id: any, value: any) => {
    if (!moment(value, "MM/DD/YYYY", true).isValid()) {
      handleFieldChange(id, value || "");
    } else {
      handleFieldChange(id, moment(value).format("MM/DD/YYYY"));
    }
  };

  const checkFormat = (e: any) => {
    let isValidDate = !isNaN(Date.parse(e?.target?.value));

    if (!isIphone) {
      if (!isValidDate) {
        handleFieldChange(id, e?.target?.value || "");
      } else {
        handleFieldChange(id, moment(e?.target?.value).format("MM/DD/YYYY"));
      }
    }
  };

  const datePickerRef = React.useRef<IDatePicker>(null);

  const formatDate = (date?: Date): string => {
    if (!date) return "";
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  return (
    <div className="mx-auto relative w-full" style={{ maxWidth: "300px" }}>
      <div className="text-start">
        <label
          className="input-label block ml-1"
          dangerouslySetInnerHTML={{ __html: title }}
          style={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#6B7280",
          }}
        ></label>
      </div>
      <div className="mt-1 relative customDatepicker">
        <DatePicker
          id={id}
          disabled={!editable}
          componentRef={datePickerRef}
          ariaLabel="Select a date"
          allowTextInput={!isIphone ? true : false}
          className={isIphone ? "iPhonePicker" : ""}
          {...(value &&
            moment(value, "MM/DD/YYYY", true).isValid() && {
              value: new Date(value),
            })}
          onSelectDate={onChange(id)}
          textField={{
            ...(value &&
              !moment(value, "MM/DD/YYYY", true).isValid() && {
                value: value,
              }),
            onChange: (event, newValue) => onChangeRaw(id, newValue),
          }}
          onBlur={(event: any) => checkFormat(event)}
          strings={defaultDatePickerStrings}
          formatDate={formatDate}
          maxDate={!isMaxDate && new Date()}
        />

        <div
          style={{ pointerEvents: "none" }}
          className="absolute right-[24px] top-[20px] transform -translate-y-1/2"
        >
          <img src={DatepickerIcons} alt="DatepickerIcons" />
        </div>
      </div>

      {errors && errors[id] && (
        <p style={{ color: "red" }} className="absolute left-0">
          {errors[id]}
        </p>
      )}
    </div>
  );
}

export default DatePickerComponent;
