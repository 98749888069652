import { Box, Card, Typography } from "@mui/material";
import DisableUserImage from "../../assets/images/disableUser.svg";

export default function DisableUser() {
  return (
    <Card
      sx={{
        height: "calc(100vh - 133px)",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Box
        sx={{
          height: "85%",
          width: "80%",
          bgcolor: "rgba(239, 246, 255, 1)",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          borderRadius: 2,
        }}
      >
        <Box
          component="img"
          src={DisableUserImage}
          height={100}
          sx={{ mb: 2 }}
        />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Sorry! Your account has been blocked
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 4 }}>
          For more information click the chat button to reach out to us if you
          think this is in error
        </Typography>
      </Box>
    </Card>
  );
}
