
function ListTextComponent(props: any) {
  const { id, textList, style } = props;

  return (
    <div className="mt-[2rem] w-full" id={id}>
      {textList.map((val: any, index: any) => (
        <div
          className={`p-[12px] rounded-[48px] rounded-[15px] shadow-none flex flex-col ${
            index % 2 === 0 ? "bg-gray-50" : ""
          }`}
          style={{
            alignItems: style?.alignment || "center",
          }}
        >
          <span
            className="leading-7 text-left text-[15px] md:text-[20px] text-black m-[12px]"
            style={{
              fontSize: style?.fontSize || "14px",
              fontWeight: style?.fontWeight || 700,
            }}
          >
            {val || ""}
          </span>
        </div>
      ))}
    </div>
  );
}
export default ListTextComponent;
