function StepperComponent(props: any) {
  const { title, id, style } = props;

  return (
    <div
      className="w-full pr-0 rounded-[48px] md:pr-[15px] mt-0 md:mt-0 rounded-[15px] shadow-none flex flex-col"
      style={{
        alignItems: "center",
        background: style?.backgroundColor,
      }}
      id={id}
    >
      <span
        className="leading-7 text-center text-[15px] md:text-[20px] text-black mb-[7px]"
        dangerouslySetInnerHTML={{ __html: title || "" }}
        style={{
          fontSize: style?.fontSize || "14px",
          fontWeight: style?.fontWeight || 700,
          margin: style?.margin || 0,
        }}
      ></span>
    </div>
  );
}
export default StepperComponent;
