import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Button, Card } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  getAllUIUsers,
  getUiUserDataShareScope,
} from "../../store/thunk/refreshThunk";
import { ViewBillingEntityDialog } from "../../components/ViewBillingEntityDialog";
import { AddB2BEntityDialog } from "../../components/AddB2BEntityDialog";
import { getAllBillingEntities } from "../../store/thunk/billingThunk";

interface EmulateUserData {
  expirationDtUtc: Date;
  id: number;
  scope: string;
  sharedWithUserEmail: string;
  sharedWithUserId: number;
  userEmail: string;
  userId: number;
}

const B2BEntities = () => {
  const dispatch = useAppDispatch();
  const { allBillingData } = useAppSelector(
    (state: any) => state.billingReducer
  );
  const [open, setOpen] = useState(false);
  const [entityData, setEntityData] = useState<any>(null);
  const [showEntity, setShowEntity] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAllBillingEntities());
  }, []);

  const viewEntity = (val: any) => {
    setEntityData(val);
    setShowEntity(true);
  };

  const currEmulationOptions = {
    sort: false,
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 20],
    count: allBillingData?.length,
    rowsPerPage: 10,
    selectableRowsHideCheckboxes: true,
    pagination: true,
    customToolbar: () => {
      return <CustomToolbar />;
    },
  };

  const allEmulationcolumns = [
    { name: "id", label: "Id", options: { sort: true } },
    { name: "billingName", label: "Name", options: { sort: true } },
    { name: "billingType", label: "Type", options: { sort: true } },
    { name: "billingId", label: "Billing Id", options: { sort: true } },
    {
      name: "disabledOn",
      label: "Disabled On",
      options: {
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return <div>{value ? value : "-"}</div>;
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div>
              <Button
                variant="outlined"
                color="info"
                sx={{ ml: 1 }}
                onClick={() => viewEntity(value)}
              >
                Details
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const headerElement = document.getElementsByTagName("header");
  const bannerElement = document.getElementsByClassName("banner-height");

  const headerHeight = bannerElement
    ? bannerElement[0]?.clientHeight + headerElement[0]?.clientHeight
    : headerElement[0]?.clientHeight || 0;
    
  const handleCloseDialog = () => {
    setOpen(false);
    setShowEntity(false);
    setEntityData(null);
  };

  const CustomToolbar = () => {
    const handleClick = () => {
      setOpen(true);
    };

    return (
      <Button onClick={handleClick} color="primary" variant="contained">
        Add new
      </Button>
    );
  };

  return (
    <Card className={`relative m-5  h-[calc(100vh_-_120px)] md:h-[calc(100vh_-_84px)]`}>
        <div
          className="table-user-list"
          style={{
            height: `calc(100vh - ${headerHeight}px - 52px )`,
            overflowY: "scroll",
          }}
        >
          <MUIDataTable
            title={"B2B Entities"}
            data={allBillingData}
            columns={allEmulationcolumns}
            options={{ ...currEmulationOptions }}
          />
        </div>
        {open && (
          <AddB2BEntityDialog open={open} handleClose={handleCloseDialog} />
        )}
        {showEntity && (
          <ViewBillingEntityDialog
            EntityId={entityData}
            open={showEntity}
            handleClose={handleCloseDialog}
          />
        )}
    </Card>
  );
};

export default B2BEntities;
