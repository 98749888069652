import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import { useAppDispatch } from "../../store/store";
import { deleteSingleUser } from "../../store/thunk/userThunk";

const DeleteComponent = (props: any) => {
  const { isDeletePopup, setIsDelete, singleUser } = props;
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    setIsDelete(false);
    const sendData = {
      id: singleUser?.id,
      isDeleted: true,
      isDisabled: singleUser?.isDisabled,
      userType: singleUser?.userType,
    };
    dispatch(deleteSingleUser({ sendData }));
  };

  return (
    <>
      <Dialog
        open={isDeletePopup}
        onClose={() => setIsDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-delete-user"
      >
        <DialogTitle id="alert-dialog-title">Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to delete ${singleUser?.id + ' - ' + singleUser?.auth0Email} user?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDelete(false)}>No</Button>
          <Button onClick={handleDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteComponent;
