import { useState, useEffect, useRef } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import {
  getStripeView,
  getStripePaymentStatus,
  getDocumentDownload,
  // getReady
} from "../../store/thunk/userThunk";
import { notificationFail } from "../../store/slices/notificationSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/store";
import LoaderComponent from "../../components/loader";
import { setDownloadLoading } from "../../store/slices/notificationSlice";

const PayAndDownloadButton = (props: any) => {
  const dispatch = useDispatch();
  const { isBoth, isPaynow } = props;

  const { downloadLoading } = useAppSelector(
    (state: any) => state.notificationReducer
  );
  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);

  let localIsBootstrapCalled: any = localStorage.getItem("isBootstrapCalled");
  const isBootstrapCalled: any = JSON.parse(localIsBootstrapCalled);

  const valueRef: any = useRef();
  // const valueReadyRef: any = useRef();
  const [isPaymentReceived, setIsPaymentReceived] = useState<any>("");
  const [messagesInterval, setMessagesInterval] = useState<any>(null);
  // const [readyInterval, setReadyInterval] = useState<any>(null);

  // Continue call to check payment status and is Ui ready api status
  useEffect(() => {
    if (isBootstrapCalled) {
      if (messagesInterval === null && isPaymentReceived == "") {
        const msgInterval = setInterval(
          () =>
            dispatch(getStripePaymentStatus()).then((res: any) => {
              if (res?.payload?.data === "paid") {
                setIsPaymentReceived(true);
                onClearInterval();
              }
            }),
          2000
        );
        setMessagesInterval(msgInterval);
      } else {
        onClearInterval();
      }
    }

    // if (bootstrapData?.refreshReady === false || bootstrapData?.refreshReady !== null) {
    //   const readyInterval = setInterval(
    //     () =>
    //       dispatch(getReady()).then((res: any) => {
    //         if (res?.payload?.data === true) {
    //           bootstrapData.refreshReady = true;
    //           localStorage.setItem("bootstrapData", JSON.stringify(bootstrapData))
    //           onClearReadyInterval();
    //         }
    //       }),
    //     2000
    //   );
    //   setReadyInterval(readyInterval);
    // } else {
    //   onClearReadyInterval();
    // }
  }, []);

  // Stop Status API after 30 seconds if status=paid/unpaid and stop api call of ready
  useEffect(() => {
    setTimeout(() => {
      onClearInterval();
      // onClearReadyInterval();
    }, 30000);
  }, []);

  const handleForm = () => {
    dispatch(getStripeView()).then((res: any) => {
      if (res.status === 200) {
        // Perform another request or navigate to the new location as needed
      } else {
        window.location.replace(res?.payload?.data);
        // Handle other status codes or do something with the response data
      }
    });
  };

  const downloadClicked = () => {
    dispatch(setDownloadLoading(true));
    dispatch(getDocumentDownload()).then((res: any) => {
      if (res?.payload !== undefined) {
        dispatch(setDownloadLoading(false));
      }
    });
  };

  useEffect(() => {
    valueRef.current = messagesInterval;
  }, [messagesInterval]);

  // useEffect(() => {
  //   valueReadyRef.current = readyInterval;
  // }, [readyInterval]);

  const onClearInterval = () => {
    setMessagesInterval(null);
    clearInterval(valueRef.current);
  };
  // const onClearReadyInterval = () => {
  //   setReadyInterval(null);
  //   clearInterval(valueReadyRef.current);
  // };

  return (
    <>
      {isBoth ? (
        <Box sx={{ marginTop: "10px", display: "flex" }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "20px",
              mr: 1,
              width: "200px",
              background: "#2563eb",
              boxShadow: "none",
              textTransform: "capitalize",
            }}
            onClick={() => handleForm()}
            disabled={true}
          // disabled={isPaymentReceived || bootstrapData?.paymentCompleted}
          >
            Pay Now
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "20px",
              mr: 1,
              textTransform: "capitalize",
              background: "#2563eb",
              width: "200px",
              boxShadow: "none",
            }}
            onClick={() => downloadClicked()}
            disabled={downloadLoading}
          >
            {downloadLoading ? <CircularProgress size={20} /> : "Download"}
          </Button>
        </Box>
      ) : isPaynow ? (
        <Button
          variant="contained"
          sx={{
            borderRadius: "20px",
            mr: 1,
            width: "200px",
            background: "#2563eb",
            boxShadow: "none",
            textTransform: "capitalize",
          }}
          onClick={() => handleForm()}
          disabled={true}
        // disabled={isPaymentReceived || bootstrapData?.paymentCompleted}
        >
          Pay Now
        </Button>
      ) : (
        <Button
          variant="contained"
          sx={{
            borderRadius: "20px",
            mr: 1,
            textTransform: "capitalize",
            background: "#2563eb",
            width: "200px",
            boxShadow: "none",
          }}
          onClick={() => downloadClicked()}
          disabled={downloadLoading}
        >
          {downloadLoading ? <CircularProgress size={20} /> : "Download"}
        </Button>
      )}
    </>
  );
};

export default PayAndDownloadButton;
