import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersList: [],
  singleUser: {},
};

export const userReducer = createSlice({
  name: "authuser",
  initialState,
  reducers: {
    setUsersList: (state, action) => ({
      ...state,
      usersList: action.payload,
    }),
    setSingleUserData: (state, action) => ({
      ...state,
      singleUser: action.payload,
    }),
  },
});

export const { setUsersList, setSingleUserData } = userReducer.actions;
export default userReducer.reducer;
