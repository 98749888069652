import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useNodesState } from "reactflow";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/store";
import { getAllPublicForm } from "../../../store/thunk/formThunk";
import LoaderComponent from "../../loader";
import FormVersion from "./FormVersion";
import FormPreview from "./FormPreview";
import "reactflow/dist/style.css";
import "./index.css";

const DnDFlow = () => {
  const dispatch: any = useDispatch();
  const [isDialog, setIsDialog] = useState(false);
  let { formId } = useParams();

  const loading = useAppSelector(
    (state: any) => state.notificationReducer.loading
  );
  const { allFormData } = useAppSelector((state: any) => state?.formReducer);

  const [formSelectChange, setFormSelectChange] = useNodesState<any>([]);
  const [uiNodes, setUiNodes] = useNodesState<any>([]);
  const [formVersion, setFormVersion] = useState("");
  const [formVersionList, setFormVersionList] = useState([]);
  const [formName, setFormName] = useState("");
  const [formNameList, setFormNameList] = useState([]);
  const [isVersion, setIsVersion] = useState<any>(0);
  const [formState, setFormState] = useState<any>({
    flow_id: "",
    version: "",
    node_data: {},
    ui_data: {},
  });

  const handleClose = () => {
    setIsDialog(false);
  };

  const addNewForm = async (data: any) => {};

  const formInputChange = (event: any) => {
    setFormSelectChange(event);
    setIsDialog(true);
  };

  const agreeWithChange = () => {
    localStorage.removeItem("user-form");
    setIsDialog(false);
    let eventData: any = formSelectChange;
    if (eventData.target.name == "version") {
      setIsVersion(true);
    } else {
      setIsVersion(false);
    }
    setFormState((formState: any) => ({
      ...formState,
      [eventData.target.name]: eventData.target.value,
    }));
  };

  useEffect(() => {
    formPreview();
  }, [formState]);

  const formPreview = () => {
    if (allFormData && allFormData.length > 0) {
      let getFormData = allFormData.filter(
        (list: any) => list.id == formState?.flow_id
      );

      if (
        getFormData &&
        getFormData?.[0]?.flowsdata &&
        getFormData[0]?.flowsdata.length > 1
      ) {
        let largest = getFormData[0]?.flowsdata.reduce(function (
          a: any,
          b: any
        ) {
          return a.version > b.version ? a.version : b.version;
        });
        if (!isVersion) {
          formState["version"] = largest;
        }
      } else if (
        getFormData[0]?.flowsdata &&
        getFormData[0]?.flowsdata.length == 1
      ) {
        let getVersion = getFormData[0]?.flowsdata?.[0]?.version;
        formState["version"] = getVersion;
      } else {
        formState["version"] = 1;
      }

      let verData =
        getFormData &&
        getFormData?.[0]?.flowsdata.filter(
          (list: any) => list.version == formState?.version
        );
      if (verData && verData.length > 0) {
        let getUiValue =
          verData && verData?.[0]?.ui_data
            ? JSON.parse(verData?.[0]?.ui_data)
            : "";
        setUiNodes(getUiValue);
      }
    }
  };

  useEffect(() => {
    if (allFormData && allFormData.length > 0) {
      let daaa: any = [];
      let formNameList1 =
        allFormData &&
        allFormData.map((item: any) => {
          let v1: any = [];
          item &&
            item.flowsdata &&
            item.flowsdata.length > 0 &&
            item.flowsdata.map((i1: any) => {
              v1.push(i1.version);
            });

          return daaa.push({
            id: item.id,
            flow_id: item.id,
            name: item.name,
            version: v1 && v1.length > 0 ? v1 : [1],
          });
        });
      setFormNameList(daaa);

      // getlast item from array
      const lastItem = allFormData[allFormData.length - 1];

      const selectedForm: any = allFormData.filter(
        (value: any, index: any) => value.id == formId
      );

      const selectedVersion =
        selectedForm &&
        selectedForm[0].flowsdata &&
        selectedForm[0].flowsdata[selectedForm[0].flowsdata.length - 1];
      let getLatestData: any = {
        flow_id: selectedVersion?.flow_id || selectedVersion?.id,
        version: selectedVersion?.version,
        node_data: selectedVersion?.node_data,
        ui_data: selectedVersion?.ui_data,
      };

      setFormState(getLatestData);
    }
  }, [allFormData]);

  const getform = () => {
    dispatch(getAllPublicForm());
  };

  useEffect(() => {
    getform();
  }, []);

  return (
    <div className="m-30">
      {loading ? <LoaderComponent /> : ""}
      <div>
        <div></div>
        <FormVersion
          formState={formState}
          formInputChange={formInputChange}
          formVersion={formVersion}
          formName={formName}
          formNameList={formNameList}
          formVersionList={formVersionList}
          setFormNameList={setFormNameList}
          addNewForm={addNewForm}
          hideAddButton={true}
          formId={formId}
        />
      </div>
      <div>
        <FormPreview uiNodes={uiNodes} formState={formState} formId={formId} />
      </div>
      <Dialog
        open={isDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to change if yes then form will be clear?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={agreeWithChange} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DnDFlow;
