import React, { useState } from "react";

export default function JSONViewer(props) {
  const {
    stringifyJSON,
    minifyJSON,
    beautifyJSON,
    clearContent,
    copyData,
    formattedData,
    copyLegend,
    copyColor,
    storeData,
    data,
    setFormattedData,
    error,
  } = props;

  return (
    <div className={`app min-v-screen  font-sans `}>
      <div className="row flex">
        <div className="col w-screen">
          <div className="box border rounded flex flex-col shadow bg-white">
            <div className="box__title bg-grey-lighter px-3 py-2 border-b">
              <div className="flex flex-wrap justify-center">
                {/* <button
                  role="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-1 px-2 mr-2 mt-2 border border-blue-700 rounded"
                  onClick={stringifyJSON}
                  title="Convert JSON into plain string"
                  disabled={error && error.includes("invalidJson")}
                >
                  Stringify
                </button>
                <button
                  role="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-1 px-2 mr-2 mt-2 border border-blue-700 rounded"
                  onClick={minifyJSON}
                  title="Minify the JSON - Remove all the whitespaces"
                  disabled={error && error.includes("invalidJson")}
                >
                  Minify
                </button> */}
                <button
                  role="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-1 px-2 mr-2 mt-2 border border-blue-700 rounded"
                  onClick={beautifyJSON}
                  title="Convert the JSON into readable format"
                  disabled={error && error.includes("invalidJson")}
                >
                  Beautify
                </button>
                {/* <button
                  role="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-1 px-2 mr-2 mt-2 border border-blue-700 rounded"
                  onClick={clearContent}
                >
                  Clear
                </button> */}
                <button
                  role="button"
                  className={`${copyColor} text-white font-medium py-1 px-2 mt-2 border border-blue-700 rounded ${
                    copyLegend === "Copied!"
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  onClick={copyData}
                  disabled={copyLegend === "Copied!" ? true : ""}
                >
                  {copyLegend}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {error && error.includes("invalidJson") && (
        <div>Please enter valid Json</div>
      )}
      <textarea
        id="jsondata"
        placeholder="Paste the JSON here"
        className={`text-grey-darkest flex-1 p-2 m-1  focus:outline-none font-mono ${
          error && error.includes("invalidJson") ? "bg-red-100" : ""
        }`}
        name="inputJSON"
        value={formattedData || "{}"}
        onChange={(e) => setFormattedData(e.target.value)}
        style={{ width: "100%", height: "54vh", margin: 0 }}
      />

      {error && error.includes("invalidJson") && (
        <div>Please enter valid Json</div>
      )}
    </div>
  );
}
