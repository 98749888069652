import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import SideLogo from "../../assets/images/logo-white.png";

const BackDropLoader = () => {
  const [isIframe, setIsIframe] = useState<boolean>(window.self !== window.top);

  return (
    <div className="custom-backdrop">
      {!isIframe && <img src={SideLogo} className="mb-3" />}
      <CircularProgress color="inherit" />
      <span className="mt-3 text-white">Getting things ready...</span>
    </div>
  );
};

export default BackDropLoader;
