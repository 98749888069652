import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useAppSelector } from "../../store/store";
import {
  anonymousUser,
  checkDependantQuestionTitle,
  checkDependantSummary,
  logErrorFunction,
} from "../../helper/service";
import SignInSignUpButton from "../../pages/payAndDownload/signInSignUpButton";
import BackDropLoader from "../loader/backDropLoader";

const SummaryScreen = (props: any) => {
  const {
    mainData,
    onClickLinkSummaryIDs,
    onClickUpdateInfoLink,
    formMainData,
    setShowRefresh,
  } = props;
  const [loadings, setLoading] = useState<any>(false);
  const [summaryData, setSummaryData] = React.useState<any>([]);

  const { getSignleFormData, uiFormPayload }: any = useAppSelector(
    (state: any) => state?.formReducer
  );
  const { summaryList } = useAppSelector(
    (state: any) => state?.documentReducer
  );
  let [formData, setFormData] = useState<any>({});

  useEffect(() => {
    setShowRefresh(true);
    return () => {
      setShowRefresh(false);
    };
  }, []);

  useEffect(() => {
    setFormData({ ...uiFormPayload });
  }, [uiFormPayload]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    if (summaryList?.length && !loadings) {
      const nodeData = JSON.parse(getSignleFormData?.flowJson);
      const mainSectionData = nodeData?.nodes?.filter(
        (obj: any) =>
          obj?.parentNode === mainData?.parentNode &&
          obj?.data?.nodeTitle?.toLowerCase() != "summary"
      );
      const tempData = mainSectionData
        ?.filter((obj: any) => checkDependantSummary(obj, formMainData))
        .map((obj: any) => {
          const sectiontemp = summaryList.filter(
            (object: any) => object?.sectionSummaryGroupKey == obj?.id
          );
          if (sectiontemp?.length) {
            return {
              ...obj,
              completePercentageData: {
                ...sectiontemp[0],
              },
            };
          } else {
            return {
              ...obj,
              completePercentageData: {
                missingFlowIds: [],
                percentComplete: 0,
                sectionKey: obj?.parentNode,
                sectionSummaryGroupKey: obj?.id,
              },
            };
          }
        });
      setSummaryData(tempData);
    }
  }, [summaryList, getSignleFormData, loadings]);

  const showTitle = (data: any) => {
    const mainTiltle = checkDependantQuestionTitle(data, formData);
    return mainTiltle;
  };

  const onClickUpdate = (e: any, datas: any, index: any) => {
    if (datas?.missingFlowIds?.length > 0) {
      const idData = datas?.missingFlowIds?.[0];
      onClickUpdateInfoLink(e, idData, index);
    } else {
      logErrorFunction(
        `The ${datas?.sectionKey} section's ${datas?.sectionSummaryGroupKey} sectionsummarygroup have no missingFlowIds exist`
      );
    }
  };

  return (
    <div>
      <div className="md:p-6 p-4">
        <h1 className="text-black text-left text-lg font-bold leading-[120%] absolute top-[6%]">
          Summary
        </h1>

        <div className="summaryList">
          {anonymousUser() ? (
            <Box
              sx={{
                background: "#eff6fe",
                borderRadius: "12px",
                m: "0 auto",
              }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <SignInSignUpButton />
            </Box>
          ) : (
            <>
              {summaryData &&
                summaryData?.length > 0 &&
                summaryData?.map((obj: any, index: any) => (
                  <Grid key={index} container spacing={2} sx={{ padding: " 20px 0px" }}>
                    <Grid item xs={12} mb={5}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            component="div"
                            sx={{ fontWeight: "600", fontSize: "15px" }}
                            className="summary-title-responsive"
                          >
                            {showTitle(obj)}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          {obj?.completePercentageData?.percentComplete <
                            100 && (
                            <Button
                              onClick={(e: any) =>
                                onClickUpdate(
                                  e,
                                  obj?.completePercentageData,
                                  index
                                )
                              }
                              variant="outlined"
                              className="card-button update-missing-responsive"
                              sx={{ background: "#fef2f2", color: "#c23838" }}
                            >
                              <HelpOutlineOutlinedIcon
                                sx={{ mr: "4px" }}
                                className="help-icon-responsive"
                              />
                              Update missing info
                            </Button>
                          )}
                          {obj?.completePercentageData?.percentComplete ===
                            100 && (
                            <>
                              <Button
                                variant="outlined"
                                className="card-button update-missing-responsive"
                                sx={{ background: "#e9fbf0", color: "#2dac5d", cursor: 'auto' }}
                              >
                                Complete
                              </Button>
                              <Button
                                onClick={(e: any) =>
                                  onClickLinkSummaryIDs(
                                    e,
                                    obj?.completePercentageData
                                      ?.sectionSummaryGroupKey,
                                    index
                                  )
                                }
                                variant="outlined"
                                className="card-button update-missing-responsive"
                                sx={{ background: "#eff6ff", color: "#2a66eb" }}
                              >
                                <EditOutlinedIcon sx={{ mr: "4px" }} />
                                Edit
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
            </>
          )}
        </div>
      </div>
      {loadings && <BackDropLoader />}
    </div>
  );
};

export default SummaryScreen;
