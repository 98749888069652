import { createSlice } from "@reduxjs/toolkit";

let unSavedFormValues = sessionStorage.getItem("unSavedFormValues");

const initialState = {
  createForm: [],
  getSignleFormData: {},
  allFormData: [],
  allForms: [],
  formVersions: [],
  versionJsonData: {},
  uiFormPayload: {},
  updateFormData: "",
  userForm: null,
  allUserForm: [],
  currentData: unSavedFormValues ? JSON.parse(unSavedFormValues) : {},
  faqDialog: false,
  previewDialog: false,
  currentQuestionList: [],
  allSectionList: [],
  allNodeData: [],
  formJsonData: {},
  currentFaqsData: [],
  currentFaqsDataList: [],
  uploadedDocuments: null,
  isCaseType: false,
};

export const formReducer = createSlice({
  name: "formflow",
  initialState,
  reducers: {
    setformCreate: (state, action) => ({
      ...state,
      createForm: action.payload,
    }),
    setAllFormData: (state, action) => ({
      ...state,
      allFormData: action.payload,
    }),
    setUpdateFormData: (state, action) => ({
      ...state,
      updateFormData: action.payload,
    }),
    setSingleFormData: (state, action) => ({
      ...state,
      getSignleFormData: action.payload,
    }),
    setUserForm: (state, action) => ({
      ...state,
      userForm: action.payload,
    }),
    setUserAllForm: (state, action) => ({
      ...state,
      allUserForm: action.payload,
    }),
    setIsSaved: (state, action) => ({
      ...state,
      isSaved: action.payload,
    }),
    setSectionId: (state, action) => ({
      ...state,
      sectionid: action.payload,
    }),
    setCurrentFormData: (state, action) => ({
      ...state,
      currentData: action.payload,
    }),
    setFaqDialog: (state, action) => ({
      ...state,
      faqDialog: action.payload,
    }),
    setPreviewDialog: (state, action) => ({
      ...state,
      previewDialog: action.payload,
    }),
    setAllForms: (state, action) => ({
      ...state,
      allForms: action.payload,
    }),
    setFormVersions: (state, action) => ({
      ...state,
      formVersions: action.payload,
    }),
    setVersionJsonData: (state, action) => ({
      ...state,
      versionJsonData: action.payload,
    }),
    setUiFormPayload: (state, action) => ({
      ...state,
      uiFormPayload: action.payload,
    }),
    setCurrentQuestionList: (state, action) => ({
      ...state,
      currentQuestionList: action.payload,
    }),
    setAllSectionList: (state, action) => ({
      ...state,
      allSectionList: action.payload,
    }),
    setAllNodeData: (state, action) => ({
      ...state,
      allNodeData: action.payload,
    }),
    setFormJsonData: (state, action) => ({
      ...state,
      formJsonData: action.payload,
    }),
    setCurrentFaqsData: (state, action) => ({
      ...state,
      currentFaqsData: action.payload,
    }),
    getCurrentFaqsData: (state, action) => ({
      ...state,
      currentFaqsDataList: action.payload,
    }),
    setBillingEntitiesData: (state, action) => ({
      ...state,
      billingEntities: action.payload,
    }),
    setUploadedDocuments: (state, action) => ({
      ...state,
      uploadedDocuments: action.payload,
    }),
    setIsCaseType: (state, action) => ({
      ...state,
      isCaseType: action.payload,
    }),
  },
});

export const {
  setformCreate,
  setIsSaved,
  setAllFormData,
  setSingleFormData,
  setUpdateFormData,
  setUserForm,
  setUserAllForm,
  setSectionId,
  setCurrentFormData,
  setFaqDialog,
  setAllForms,
  setFormVersions,
  setPreviewDialog,
  setVersionJsonData,
  setUiFormPayload,
  setCurrentQuestionList,
  setAllSectionList,
  setAllNodeData,
  setFormJsonData,
  setCurrentFaqsData,
  getCurrentFaqsData,
  setBillingEntitiesData,
  setUploadedDocuments,
  setIsCaseType,
} = formReducer.actions;
export default formReducer.reducer;
