import React from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Input,
  List,
  ListItem,
  Paper,
} from "@mui/material";

interface Annotation {
  type: string;
  x: number;
  y: number;
  width: number;
  height: number;
  value?: string;
}

interface SidebarProps {
  annotations: Annotation[];
  handleInputChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  annotationType: string;
  setAnnotationType: (value: string) => void;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pdfFile: File | null;
  count: number;
}

const Sidebar = ({
  annotations,
  handleInputChange,
  annotationType,
  setAnnotationType,
  onFileChange,
  pdfFile,
  count,
}: any) => {
  return (
    <Box
      sx={{
        width: 300,
        padding: 2,
        borderRight: 1,
        borderColor: "divider",
        overflowY: "auto",
        height: "calc(100vh - 133px)",
      }}
    >
      <Typography variant="h6" component="div" gutterBottom>
        Annotation Type:
      </Typography>
      <Select
        value={annotationType}
        onChange={(e) => setAnnotationType(e.target.value)}
        sx={{ height: 40, width: 100, borderRadius: 1 }}
      >
        <MenuItem value="input">Input Text</MenuItem>
        <MenuItem value="dropdown">Dropdown</MenuItem>
        <MenuItem value="checkbox">Checkbox</MenuItem>
      </Select>
      <Input
        type="file"
        inputProps={{ accept: "application/pdf" }}
        onChange={onFileChange}
        sx={{ marginBottom: 2, marginTop: 2 }}
      />
      {annotations?.length > 0 && (
        <>
          <Typography variant="h6" component="div" gutterBottom>
            Annotations
          </Typography>
          <List>
            {annotations?.map((annotation: any, index: any) => (
              <ListItem key={index}>
                <Paper sx={{ padding: 1, width: "100%" }}>
                  <Typography variant="body2">
                    Type: {annotation.type}
                  </Typography>
                  <Typography variant="body2">
                    Coordinates: ({annotation.x}, {annotation.y})
                  </Typography>
                  <Typography variant="body2">
                    Width: {annotation.width}
                  </Typography>
                  <Typography variant="body2">
                    Height: {annotation.height}
                  </Typography>
                  {/* {annotation.type === "input" && (
                    <Input
                      type="text"
                      value={annotation.value}
                      onChange={(e) => handleInputChange(index , e)}
                    />
                  )} */}
                </Paper>
              </ListItem>
            ))}
          </List>
          <Typography variant="h6" component="div" gutterBottom>
            Annotations JSON
          </Typography>
          <Paper sx={{ padding: 1, whiteSpace: "pre-wrap" }}>
            {JSON.stringify(annotations, null, 1)}
          </Paper>
        </>
      )}
    </Box>
  );
};

export default Sidebar;
