import { useState, useEffect } from "react";
import RightIcon from "../../assets/images/CircleRight.svg";

function MultiSelection(props: any) {
  const {
    multiSelect,
    options,
    title,
    editable,
    id,
    handleFieldChange,
    value,
    errors,
  } = props;
  const [checkedItems, setCheckedItems] = useState<any>([]);

  useEffect(() => {
    if (Array.isArray(value)) {
      setCheckedItems(value);
    }
  }, [value]);

  const handleCheckboxChange = (name: any) => {
    if (editable) {
      const checkboxValue: any = name;

      if (multiSelect) {
        let tempArr = [...value];
        const index = tempArr.indexOf(checkboxValue);
        if (index !== -1) {
          // Value found, so remove it
          tempArr.splice(index, 1);
        } else {
          tempArr.push(checkboxValue);
        }
        setCheckedItems([...tempArr]);
        handleFieldChange(id, [...tempArr]);
      } else {
        setCheckedItems([checkboxValue]);
        handleFieldChange(id, [checkboxValue]);
      }
    }
  };

  return (
    <div
      className="mt-0 mb-5 w-[100%] flex flex-col"
      style={{ margin: "0px" }}
      id={id}
    >
      <h2
        className="text-center font-bold leading-= 7 text-left text-[20px] text-black mb-[7px]"
        style={{ margin: "0px" }}
        dangerouslySetInnerHTML={{ __html: title }}
      >
        {/* {title} hide for dangerouslySetInnerHTML */}
      </h2>
      <div className="mt-8 gap-3 flex justify-center flex-wrap flex self-center max-w-[70%]">
        {options &&
          options.map((row: any, index: number) => (
            <div
              id={row?.value}
              key={index}
              className={` flex flex-col items-center cursor-pointer p-3 max-w-[200px] min-w-[200px] min-h-[160px] pl-3 pr-3 rounded-[20px] border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-[#D1D5DB] focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50  shadow-[2px_2px_4px_#ddd] ${
                checkedItems?.includes(row.value)
                  ? "checked-block"
                  : "unchecked-block"
              } `}
              style={{ ...(!editable && { filter: "contrast(0.5)" }) }}
              onClick={() => handleCheckboxChange(row.value?.toString())}
            >
              <label
                className={`checked-block-label select-none w-[50px] mb-[10px] border rounded-full border-[#D9D9D9] bg-[#D9D9D9] flex items-center justify-center cursor-pointer`}
              >
                <img
                  src={RightIcon}
                  alt="RightIcon"
                  style={{ width: "50px", filter: "grayscale(1)" }}
                  className="checkedItems select-none"
                />

                {checkedItems?.includes(row.value) && (
                  <img
                    src={RightIcon}
                    alt="RightIcon"
                    style={{
                      width: "50px",
                      position: "absolute",
                    }}
                    className="select-none"
                  />
                )}
              </label>
              <label
                className=" w-[180px] text-[#2563eb] font-bold text-center select-none"
                style={{ wordBreak: "break-word", fontFamily: "Sen" }}
              >
                {row.key}
              </label>
            </div>
          ))}
      </div>

      {errors && errors[id] && (
        <p style={{ color: "red", textAlign: "center", marginTop: "12px" }}>
          {errors[id]}
        </p>
      )}
    </div>
  );
}

export default MultiSelection;
