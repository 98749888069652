import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import { deleteSingleUser } from '../../store/thunk/userThunk';
import { useAppDispatch } from '../../store/store';

const DisabledComponent = (props: any) => {
  const { isDisable, setIsDisable, singleUser, isCheckedToggle } = props;
  const dispatch = useAppDispatch();
  
  const handleDelete = () => {
    setIsDisable(false);
    const sendData = {
      id: singleUser?.id,
      isDeleted: singleUser?.isDeleted,
      isDisabled: isCheckedToggle,
      userType: singleUser?.userType
    }
    dispatch(deleteSingleUser({ sendData }));
  };

  return (
    <>
     <Dialog
          open={isDisable}
          onClose={() => setIsDisable(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='dialog-delete-user'
        >
          <DialogTitle id="alert-dialog-title">
            Disable User
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to disable this user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDisable(false)}>No</Button>
            <Button onClick={handleDelete} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
};

export default DisabledComponent;
