import { useState, useEffect } from "react";
import { useNodesState } from "reactflow";
import { useDispatch } from "react-redux";

import { getAllForm } from "../../store/thunk/formThunk";
import FormVersion from "./FormVersion";
import { useAppSelector } from "../../store/store";
import LoaderComponent from "../loader";
import FormPreview from "./FormPreview";
import "reactflow/dist/style.css";
import "./index.css";

const DnDFlow = () => {
  const dispatch: any = useDispatch();

  const [uiNodes, setUiNodes] = useNodesState<any>([]);

  const [formVersion, setFormVersion] = useState("");
  const [formVersionList, setFormVersionList] = useState([]);
  const [formName, setFormName] = useState("");
  const [formNameList, setFormNameList] = useState([]);

  const [isVersion, setIsVersion] = useState<any>(0);

  const loading = useAppSelector(
    (state: any) => state.notificationReducer.loading
  );

  const { allFormData } = useAppSelector((state: any) => state?.formReducer);

  const [formState, setFormState] = useState<any>({
    flow_id: "",
    version: "",
    node_data: {},
    ui_data: {},
  });

  const addNewForm = async (data: any) => {};

  const formInputChange = (event: any) => {
    if (event.target.name == "version") {
      setIsVersion(true);
    } else {
      setIsVersion(false);
    }
    setFormState((formState: any) => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    formPreview();
  }, [formState]);

  const formPreview = () => {
    if (allFormData && allFormData.length > 0) {
      let getFormData = allFormData.filter(
        (list: any) => list.id == formState?.flow_id
      );

      if (
        getFormData &&
        getFormData?.[0]?.flowsdata &&
        getFormData[0]?.flowsdata.length > 1
      ) {
        let largest = getFormData[0]?.flowsdata.reduce(function (
          a: any,
          b: any
        ) {
          return a.version > b.version ? a.version : b.version;
        });
        if (!isVersion) {
          formState["version"] = largest;
        }
      } else if (
        getFormData[0]?.flowsdata &&
        getFormData[0]?.flowsdata.length == 1
      ) {
        let getVersion = getFormData[0]?.flowsdata?.[0]?.version;
        formState["version"] = getVersion;
      } else {
        formState["version"] = 1;
      }

      let verData =
        getFormData &&
        getFormData?.[0]?.flowsdata.filter(
          (list: any) => list.version == formState?.version
        );
      if (verData && verData.length > 0) {
        let idii = verData[0]?.id;
        let getValue =
          verData && verData?.[0]?.node_data
            ? JSON.parse(verData?.[0]?.node_data)
            : "";
        let getUiValue =
          verData && verData?.[0]?.ui_data
            ? JSON.parse(verData?.[0]?.ui_data)
            : "";
        setUiNodes(getUiValue);
      }
    }
  };

  useEffect(() => {
    if (allFormData && allFormData.length > 0) {
      let daaa: any = [];
      let formNameList1 =
        allFormData &&
        allFormData.map((item: any) => {
          let v1: any = [];
          item &&
            item.flowsdata &&
            item.flowsdata.length > 0 &&
            item.flowsdata.map((i1: any) => {
              v1.push(i1.version);
            });

          return daaa.push({
            id: item.id,
            flow_id: item.id,
            name: item.name,
            version: v1 && v1.length > 0 ? v1 : [1],
          });
        });
      setFormNameList(daaa);

      // getlast item from array
      const lastItem = allFormData[allFormData.length - 1];

      const lastVersion =
        lastItem &&
        lastItem.flowsdata &&
        lastItem.flowsdata[lastItem.flowsdata.length - 1];

      let getLatestData: any = {
        flow_id: lastVersion?.flow_id || lastItem?.id,
        version: lastVersion?.version,
        node_data: lastVersion?.node_data,
        ui_data: lastVersion?.ui_data,
      };
      setFormState(getLatestData);
    }
  }, [allFormData]);

  const getform = () => {
    dispatch(getAllForm());
  };

  useEffect(() => {
    getform();
  }, []);

  return (
    <>
      {loading ? <LoaderComponent /> : ""}
      <div>
        <FormVersion
          formState={formState}
          formInputChange={formInputChange}
          formVersion={formVersion}
          formName={formName}
          formNameList={formNameList}
          formVersionList={formVersionList}
          setFormNameList={setFormNameList}
          addNewForm={addNewForm}
          hideAddButton={true}
        />
      </div>
      <div>
        <FormPreview uiNodes={uiNodes} formState={formState} />
      </div>
    </>
  );
};

export default DnDFlow;
