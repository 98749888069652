import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setLoading,
  notificationFail,
  notificationSuccess,
  notificationClear,
  setUploadLoading,
} from "../slices/notificationSlice";
import {
  setAllDocumentData,
  setProgressData,
  setSummaryAllData,
  setUploadedDocument,
} from "../slices/documentSlice";
import dotnetApiClient from "../../config/dotnetApiClient";
import { anonymousUser, generateGuid } from "../../helper/service";
import { getCurrentFaqsData } from "../slices/flowFormSlice";
import Logger from "../../helper/loggerfunction";

const logger = new Logger();

let localData: any = localStorage.getItem("bootstrapData");
const bootstrapData: any = JSON.parse(localData);

const handleLogout = () => {
  const isIframe = window.self !== window.top;

  let redirectUrl = "";
  localStorage.clear();
  sessionStorage.clear();

  if (isIframe) {
    redirectUrl = window.IFRAME_REDIRECT_URL;
    if (window.top) {
      window.top.location.href = redirectUrl;
    }
  } else {
    redirectUrl = window.CURRENT_HOST;
  }
};

export const getAllDocumentData: any = createAsyncThunk(
  "getAllDocumentData",
  async (_request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(`UiEvidenceDocumentType/`);
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(setAllDocumentData(response?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const updateAssociation: any = createAsyncThunk(
  "updateAssociation",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().put(
        `UiEvidenceDocumentType/typeText/${_request?.id}`,
        `${_request?.name || ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(setLoading(false));

      if (response?.data) {
        // dispatch(setAllDocumentData(response?.data?.data));
        dispatch(getAllDocumentData());
        dispatch(notificationSuccess("Name Updated successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const addAssociation: any = createAsyncThunk(
  "addAssociation",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `UiEvidenceDocumentType`,
        _request
      );
      dispatch(setLoading(false));

      if (response?.data) {
        // dispatch(setAllDocumentData(response?.data?.data));
        dispatch(getAllDocumentData());
        dispatch(notificationSuccess("Added successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const uploadDocuments: any = createAsyncThunk(
  "uploadDocuments",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setUploadLoading(true));
      const formData = new FormData();
      _request?.files?.map((val: any) => {
        formData.append("files", val);
      });
      const response = await dotnetApiClient().post(
        `UiEvidenceDocument/files/upload/${_request.documentTypeId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for FormData
          },
        }
      );

      if (response?.data) {
        dispatch(setUploadLoading(false));
        dispatch(
          getUpoadedDocuments({
            documentTypeId: _request?.documentTypeId || "",
          })
        );
        dispatch(getAllDocumentData());
        // dispatch(setAllDocumentData(response?.data?.data));
        dispatch(notificationSuccess("Added successfully"));
      } else {
        dispatch(setLoading(false));
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getUpoadedDocuments: any = createAsyncThunk(
  "getUpoadedDocuments",
  async (_request: any, { dispatch }) => {
    try {
      const response = await dotnetApiClient().get(
        `UiEvidenceDocument/documentType/${_request.documentTypeId}`
      );

      if (response?.data) {
        dispatch(setUploadedDocument(response?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const deleteDocument: any = createAsyncThunk(
  "deleteDocument",
  async (_request: any, { dispatch }) => {
    try {
      const response = await dotnetApiClient().delete(
        `/UiEvidenceDocument/${_request.id}`
      );

      if (response) {
        dispatch(
          getUpoadedDocuments({
            documentTypeId: _request?.documentTypeId || "",
          })
        );
        dispatch(getAllDocumentData());
        dispatch(notificationSuccess("Document deleted successfully"));
      } else {
        dispatch(notificationFail("Something went wrong"));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const downloadDocument: any = createAsyncThunk(
  "downloadDocument",
  async (_request: any, { dispatch }) => {
    try {
      const baseUrl = window.API_URL;
      fetch(`${baseUrl}/UiEvidenceDocument/files/${_request?.filename}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
      })
        .then((response: any) => response.blob())
        .then((image: any) => {
          const image_url = URL.createObjectURL(image);
          return image_url;
        })
        .then((url: any) => {
          let a = document.createElement("a");
          a.href = url;
          a.download = "image.jpg";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const updateOrder: any = createAsyncThunk(
  "updateOrder",
  async (_request: any, { dispatch }) => {
    try {
      const response = await dotnetApiClient().post(
        `/UiEvidenceDocument/documentOrder`,
        {
          ..._request.newOrder,
        }
      );

      if (response?.status == 200) {
        dispatch(
          getUpoadedDocuments({
            documentTypeId: _request?.documentTypeId || "",
          })
        );
        // dispatch(setAllDocumentData(response?.data?.data));
        dispatch(notificationSuccess("Order updated successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

// Wordpress get FAQ API call
export const getFaqList: any = createAsyncThunk(
  "getFaqList",
  async (_request: any, { dispatch }) => {
    try {
      const { faqData } = _request;
      // const faqs = faqData.join(", ").replace(/,([^,]*)$/, '$1');
      const faqs = faqData.join(",");

      const response = await dotnetApiClient().get(`/faq?faqIds=${faqs}`);
      if (response?.status == 200) {
        dispatch(getCurrentFaqsData(response?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getProgressData: any = createAsyncThunk(
  "getProgressData",
  async (_request, { dispatch }) => {
    const caseTypeId = JSON.parse(
      localStorage.getItem("caseTypeDetails") || "{}"
    )?.caseId;
    let sessionID = sessionStorage.getItem("Usapath-Session-Id");

    if (caseTypeId) {
      try {
        if (anonymousUser()) {
          return true;
        }
        dispatch(setLoading(true));
        const response = await dotnetApiClient().get(
          `UiProgress/Section?caseType=${caseTypeId}`
        );

        dispatch(setLoading(false));
        if (response?.data) {
          dispatch(setProgressData(response?.data));
        } else {
          dispatch(setLoading(false));
        }
        
        let concatString: any = JSON.stringify({
          message: "Section POST API called",
          sessionID: sessionID,
          UserId: bootstrapData?.id,
          RequestId: generateGuid()
        });

        const encodedString = btoa(concatString);

        logger.log({
          level: 'info', detailsJson: encodedString, component: 'UiProgress/Section POST API called'
        });

        return response;
      } catch (error: any) {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 406
        ) {
          handleLogout();
        }

        let concatString: any = JSON.stringify({
          message: "Section POST API called error",
          sessionID: sessionID,
          UserId: bootstrapData?.id,
          RequestId: generateGuid()
        })
        const encodedString = btoa(concatString);

        logger.log({
          level: 'error', detailsJson: encodedString, component: 'Section POST API called error'
        });

        dispatch(setLoading(false));
        dispatch(
          notificationFail(
            "We have detected an error. Please contact support for assistance."
          )
        );
      }
    }
  }
);

export const getSummaryData: any = createAsyncThunk(
  "getSummaryData",
  async (_request, { dispatch }) => {
    const caseTypeId = JSON.parse(
      localStorage.getItem("caseTypeDetails") || "{}"
    )?.caseId;
    if (caseTypeId) {
      try {
        if (anonymousUser()) {
          dispatch(setLoading(false));
          return true;
        }
        dispatch(setLoading(true));
        const response = await dotnetApiClient().get(
          `UiProgress/SectionSummaryGroup?caseType=${caseTypeId}`
        );
        dispatch(setLoading(false));
        if (response?.data) {
          dispatch(setSummaryAllData(response?.data));
        } else {
          dispatch(setLoading(false));
          dispatch(
            notificationFail(
              response?.data?.message ||
              "We have detected an error. Please contact support for assistance."
            )
          );
        }
      } catch (error: any) {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 406
        ) {
          handleLogout();
        }
        dispatch(setLoading(false));
        dispatch(
          notificationFail(
            "We have detected an error. Please contact support for assistance."
          )
        );
      }
    }
  }
);
