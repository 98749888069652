import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setLoading,
  notificationFail,
  notificationSuccess,
} from "../slices/notificationSlice";
import dotnetApiClient from "../../config/dotnetApiClient";
import { setAllBillingData, setEntityData, setSidebarLogo } from "../slices/billingSlice";

const handleLogout = () => {
  const isIframe = window.self !== window.top;

  let redirectUrl = '';
  localStorage.clear();
  sessionStorage.clear();

  if (isIframe) {
    redirectUrl = window.IFRAME_REDIRECT_URL;
    if (window.top) {
      window.top.location.href = redirectUrl;
    }
  } else {
    redirectUrl = window.CURRENT_HOST;
  }
}

export const getAllBillingEntities: any = createAsyncThunk(
  "getAllBillingEntities",
  async (_request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(`/Billing`);
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(setAllBillingData(response?.data));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
export const addB2BEntity: any = createAsyncThunk(
  "addB2BEntity",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(`Billing`, _request);
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(getAllBillingEntities());
        dispatch(notificationSuccess("Added successfully"));
      }
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const addB2BEntityLogo: any = createAsyncThunk(
  "addB2BEntityLogo",
  async (_request: any, { dispatch }) => {
    let { id, logo_file } = _request;
    let formData = new FormData();
    formData.append('Logo', logo_file);

    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(`/Billing/${id}/upload-logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(notificationSuccess("Logo added successfully"));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getB2BEntityLogo: any = createAsyncThunk(
  "getB2BEntityLogo",
  async (_request: any, { dispatch }) => {
    let { id } = _request;

    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(`/Billing/${id}/logo`, {
        headers: {
          Accept: 'application/octet-stream', // Ensure binary data is returned
        },
        responseType: 'blob', // Handle response as blob for binary data
      });

      dispatch(setLoading(false));

      if (response?.data) {
        const imageUrl = URL.createObjectURL(response.data);
        return imageUrl; // Return the blob URL for use in your app
      }
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      if(error?.response?.status !== 404) {
        dispatch(notificationFail("Something went wrong"));
      }
    }
  }
);

export const resetPrimaryKey: any = createAsyncThunk(
  "resetPrimaryKey",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `Billing/${_request.id}/reset-primary-key`,
        _request
      );
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(getBillingDetails(_request));
        dispatch(notificationSuccess("Primary-key reset successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
export const resetSecondaryKey: any = createAsyncThunk(
  "resetSecondaryKey",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `Billing/${_request.id}/reset-secondary-key`,
        _request
      );
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(getBillingDetails(_request));
        dispatch(notificationSuccess("Secondary-key reset successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const disabledKey: any = createAsyncThunk(
  "disabledKey",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `Billing/${_request.id}/disable`,
        _request
      );
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(getAllBillingEntities());
        dispatch(getBillingDetails(_request));
        dispatch(notificationSuccess("Reset successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
export const enableKey: any = createAsyncThunk(
  "disabledKey",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `Billing/${_request.id}/enable`,
        _request
      );
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(getAllBillingEntities());
        dispatch(getBillingDetails(_request));
        dispatch(notificationSuccess("Reset successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
export const getBillingDetails: any = createAsyncThunk(
  "getBillingDetails",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(
        `Billing/${_request.id}`,
        _request
      );
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setEntityData(response?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getLogoForClient: any = createAsyncThunk(
  "getLogoForClient",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get('/Billing/logo', { responseType: 'blob' });
      dispatch(setLoading(false));
      if (response?.data) {
        const data = response.data;
        const blob = new Blob([data], { type: data.type });
        const url = URL.createObjectURL(blob);
        dispatch(setSidebarLogo(url));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
    }
  }
);
