import React, { useEffect, useState } from "react";
import { Box, Divider, IconButton, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { Tooltip } from "@material-tailwind/react";
import { styled } from "@mui/material/styles";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandIcon from "@mui/icons-material/ExpandLessRounded";
import PostContents from "./postContents";
import { getFaqList } from "../../store/thunk/documentThunk";
import { useAppSelector } from "../../store/store";
import { getCurrentFaqsData } from "../../store/slices/flowFormSlice";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1, 0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  borderBottom: "1px solid #F3F4F6",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  alignItems: "flex-start",
  padding: theme.spacing(0),
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginTop: "12px",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
}));

export default function FAQs(props: any) {
  let { toggleFaqDrawer } = props;

  const { currentFaqsData, currentFaqsDataList } = useAppSelector(
    (state: any) => state?.formReducer
  );
  const [expanded, setExpanded] = React.useState<number | false>(0);
  const [loading, setLoading] = useState<any>(true);
  const [faqArr, setFaqArr] = useState<any>([]);
  const dispatch = useDispatch();

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  // Empty the Reducer data
  useEffect(() => {
    dispatch(getCurrentFaqsData([]));
  }, []);

  // If I found currentFaqsData
  useEffect(() => {
    setLoading(true);
    if (currentFaqsData && currentFaqsData?.length > 0) {
      dispatch(getFaqList({ faqData: currentFaqsData })).then((res: any) => {
        setLoading(false);
      });
    } else {
      setFaqArr([]);
      setLoading(false);
    }
  }, [currentFaqsData]);

  useEffect(() => {
    if (currentFaqsDataList && currentFaqsDataList?.length > 0) {
      setFaqArr(currentFaqsDataList);
    } else {
      setFaqArr([]);
    }
  }, [currentFaqsDataList]);

  return (
    <Box>
      <DrawerHeader style={{ minHeight: "77px" }}>
        <span className="Sen-Bold self-center text-md font-extrabold whitespace-nowrap text-[#0C1932]">
          Frequently Asked Questions
        </span>
        <Tooltip title="close" arrow>
          <IconButton onClick={toggleFaqDrawer}>
            <CloseIcon sx={{ color: "black", fontSize: "18px" }} />
          </IconButton>
        </Tooltip>
      </DrawerHeader>
      <Divider />

      <div className="mx-3 my-3">
        <span>
          The search results here are contextualized for ease of access. You can
          go{" "}
          <a
            href="https://help.usapath.com"
            target="_blank"
            style={{ color: "#2563EB" }}
          >
            here
          </a>{" "}
          for all articles and FAQ's.
        </span>
      </div>

      <div className="mx-4 my-0">
        {loading ? (
          <Box
            sx={{
              height: "calc(100vh - 80px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : faqArr && faqArr?.length > 0 ? (
          faqArr?.map((obj: any, index: any) => (
            <Accordion
              key={index}
              expanded={expanded === index || false}
              onChange={handleChange(index)}
              onPointerOverCapture={() => {}}
              onPointerMoveCapture={() => {}}
            >
              <AccordionSummary
                expandIcon={<ExpandIcon sx={{ color: "black" }} />}
              >
                <span className="Sen-Bold self-center text-sm font-bold text-[#0C1932]">
                  {obj?.title}
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <span className="self-center font-medium text-xs text-[#9CA3AF]">
                  <PostContents contentData={obj?.body || ""} />
                </span>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              width: "100%",
              textAlign: "center",
            }}
          >
            No faqs
          </Box>
        )}
      </div>
    </Box>
  );
}
