import { toCamelCase, toUpperCase } from "../../helper/service";

function InputField(props: any) {
  const {
    style,
    title,
    placeholder,
    editable,
    handleFieldChange,
    id,
    value,
    errors,
    formatting
  } = props;

  return (
    <div className="w-[100%]" style={{ maxWidth: "300px" }}>
      <div className="text-start">
        <label
          className="input-label block ml-1"
          dangerouslySetInnerHTML={{ __html: title }}
          style={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#6B7280",
          }}
        >
        </label>
      </div>
      <input
        id={id}
        disabled={!editable}
        onBlur={() => formatting === "cap_first_letter" ? handleFieldChange(id, toCamelCase(value)) : formatting === "all_caps" ? handleFieldChange(id, toUpperCase(value)) : handleFieldChange(id, value)}
        className="custom-input px-6 py-1.5 peer h-full w-full rounded-[12px] border border-blue-gray-200 border-t-[#D1D5DB] bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-1 focus:border-[#111827] disabled:text-[rgba(0,0,0,0.38)] disabled:bg-[rgba(0,0,0,0.05)]"
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleFieldChange(id, e?.target?.value)}
      />
      {/* <textarea rows={3}></textarea> */}
      {errors && errors[id] && <p style={{ color: "red" }}>{errors[id]}</p>}
    </div>
  );
}

export default InputField;
