import { useEffect, useState } from "react";
import { Grid, Divider } from "@mui/material";
import { Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { addUserForm } from "../../store/thunk/formThunk";
import { useAppSelector } from "../../store/store";
import "./index.css";
import "reactflow/dist/style.css";

const FormPreview = (props: any) => {
  const dispatch: any = useDispatch();
  const { uiNodes, formState } = props;
  const [fields, setFields] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const { userForm } = useAppSelector((state: any) => state?.formReducer);

  useEffect(() => {
    if (userForm && userForm.form_data) {
      let fieldJson = JSON.parse(userForm.form_data);
      setFields(fieldJson);
    } else if (uiNodes && uiNodes.length > 0) {
      if (userForm && userForm.form_data) {
        let fieldJson = JSON.parse(userForm.form_data);
        setFields(fieldJson);
      } else {
        let tempState: any = {};
        uiNodes
          .filter((val: any) => val?.data?.type?.toLowerCase() == "question")
          .map((val: any) => {
            tempState[val.id] = "";
          });
        setFields(tempState);
      }
    }
  }, [userForm]);

  useEffect(() => {
    if (uiNodes && uiNodes.length > 0) {
      if (userForm && userForm.form_data) {
        let fieldJson = JSON.parse(userForm.form_data);
        setFields(fieldJson);
      } else {
        let tempState: any = {};
        uiNodes
          .filter((val: any) => val?.data?.type?.toLowerCase() == "question")
          .map((val: any) => {
            tempState[val.id] = "";
          });
        setFields(tempState);
      }
    }
  }, [uiNodes]);

  const handleChange = (event: any) => {
    setFields({
      ...fields,
      [event.target.name]: event.target.value,
    });
  };

  const handleSave = () => {
    dispatch(
      addUserForm({
        form_id: formState.flow_id,
        form_data: JSON.stringify(fields),
        id: userForm ? userForm.id : null,
      })
    );
  };

  let tempNodes: any = [];

  const renderFields = (field: any) => {
    let type = field?.data?.type;
    let filedProp = field?.data?.properties;
    let placeholder = filedProp?.properties?.placeholder;
    let fieldTitle = field?.data?.properties?.title;
    if (type && filedProp?.type?.toLowerCase() == "textbox" && filedProp) {
      return (
        <>
          <div className="que-container">{field.title}</div>
          <div className="form-field-container">
            <label>{fieldTitle}</label>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={placeholder || ""}
              onChange={handleChange}
              name={field.id}
              value={fields[field.id]}
              onBlur={handleSave}
              helperText={errors[field.id] || ""}
              error={errors[field.id]}
            />
          </div>
        </>
      );
    }
    if (filedProp?.type?.toLowerCase() == "dropdown" && filedProp) {
      return (
        <>
          <div className="que-container">{field.title}</div>
          <div className="form-field-container">
            <label>{fieldTitle}</label>
            <Select
              fullWidth
              style={{ minWidth: 200 }}
              onChange={handleChange}
              name={field.id}
              value={fields[field.id] || ""}
              onBlur={handleSave}
              error={errors[field.id]}
            >
              <MenuItem value={"Yes "}>Yes</MenuItem>
              <MenuItem value={"No "}>No</MenuItem>
            </Select>
          </div>
        </>
      );
    }
    if (filedProp?.type?.toLowerCase() == "datetime" && filedProp) {
      return (
        <>
          <div className="que-container">{field.title}</div>
          <div className="form-field-container">
            <label>{fieldTitle}</label>
            <TextField
              type="date"
              label={placeholder}
              variant="outlined"
              placeholder={placeholder || ""}
              onChange={handleChange}
              name={field.id}
              value={fields[field.id]}
              onBlur={handleSave}
              helperText={errors[field.id] || ""}
              error={errors[field.id]}
            />
          </div>
        </>
      );
    }
    return field.title;
  };

  const renderUiForms = (myNodes: any) => {
    return myNodes.map((val: any, index: any) => {
      if (!tempNodes.includes(val.id)) {
        tempNodes.push(val.id);
        if (val.children && val.children.length > 0) {
          return (
            <Grid container>
              {val?.data?.type?.toLowerCase() == "section" ? (
                <>
                  <Grid md={12} sx={{ background: "#cac4eb", mt: 4, p: 2 }}>
                    <Typography variant={"h5"}>{val.title}</Typography>
                  </Grid>
                  {renderUiForms(
                    uiNodes.filter((row: any) => val.children.includes(row.id))
                  )}
                </>
              ) : (
                <>
                  <Grid
                    md={12}
                    sx={{ background: "#ebe9f9", mt: 4, p: 2, pl: 4, ml: 3 }}
                  >
                    <Typography variant={"h6"}>{val.title}</Typography>
                  </Grid>
                  {renderUiForms(
                    uiNodes.filter((row: any) => val.children.includes(row.id))
                  )}
                  <Grid md={12} sx={{ p: 2, pt: 4 }}>
                    <Divider />
                  </Grid>
                </>
              )}
            </Grid>
          );
        } else {
          return (
            <Grid key={index} md={4} sx={{ pl: 4, mt: 1 }}>
              {renderFields(val)}
            </Grid>
          );
        }
      }
    });
  };

  const handleSubmit = () => {
    if (uiNodes && uiNodes.length > 0) {
      let questionNodes = uiNodes.filter(
        (val: any) => val?.data?.type?.toLowerCase() == "question"
      );

      let errors: any = {};

      questionNodes.map((row: any) => {
        let rowFormValue = fields[row.id];
        let validations = row.data?.properties?.properties?.validations;
        if (validations && validations.length > 0) {
          validations.map((vld: any) => {
            if (vld.type === "regex") {
              var re = new RegExp(vld.value);
              if (!re.test(rowFormValue)) {
                errors[row.id] = vld.message || "please enter valid value";
              }
            }
            if (vld.type === "length") {
              if (rowFormValue.length > vld.value) {
                errors[row.id] = vld.message || "please enter valid value";
              }
            }
            if (row.data?.properties.required === "{true}") {
              if (!rowFormValue) {
                errors[row.id] = "please enter valid value";
              }
            }
          });
        }
      });
      setErrors(errors);
    }
  };

  return (
    <>
      <div className="formPreview">
        {renderUiForms(uiNodes)}
        <Button variant="contained" onClick={handleSubmit}>
          Save Form
        </Button>
      </div>
    </>
  );
};

export default FormPreview;
